import {
    BatchPricingResults, FileUpload, LoanPricingResult, Notification, NotificationType, PricingUploadBatch
} from '@api';
import {
    HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState
} from '@microsoft/signalr';
import * as Sentry from '@sentry/react';

import { getAuthToken } from '../api-utils';

import { WebSocketEvent } from './WebSocketEvent';
import { WebSocketEventType } from './WebSocketEventType';


export interface WebSocketEventData<TData = unknown> {
    type: WebSocketEventType;
    data: TData;
}

type WebSocketSubscriptionCallback<TData> = (data: TData) => void;

const HUB_METHOD_NAME = 'HandleMessage'; // we use HandleMessage for all events

/**
 * Websocket for Premicorr
 */
export class PremicorrWebSocket {
    private readonly _eventTarget: EventTarget = new EventTarget();
    private _connection: HubConnection | undefined;
    private _mockNotificationId = 10;

    constructor() {
        this._handleMessage = this._handleMessage.bind(this);
    }

    /**
     * Recreates the underlying HubConnection with the given url. If the connection already exists, it will be closed
     * before creating the new instance.
     *
     * @param url - The url of the websocket to connect to
     */
    set url(url: string) {
        try {
            window.removeEventListener('focus', this._handleWindowFocus);

            if (this._connection) {
                this.close();
            }

            this._connection = new HubConnectionBuilder()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: async () => (await getAuthToken()).replace('Bearer ', '')
                })
                .withAutomaticReconnect()
                .build();

            this._connection.on(HUB_METHOD_NAME, this._handleMessage);
            window.addEventListener('focus', this._handleWindowFocus);
        } catch (error) {
            Sentry.captureException(error, {
                extra: { url }
            });
        }
    }

    /**
     * Gets the url of the underlying connection. Since set only accepts a string, this get also needs to
     * return a string, hence the returning of an empty string if rety socket isn't set yet
     */
    get url() {
        return this._connection?.baseUrl || '';
    }

    /**
     * Handles window focus event by checking connection status and reconnecting if needed
     */
    private _handleWindowFocus = async () => {
        try {
            console.log('Window focused - current connection state:', this._connection?.state);
            if (this._connection && this._connection.state === HubConnectionState.Disconnected) {
                console.log('Attempting to reconnect due to window focus');
                await this.open();
                console.log('Reconnection attempt complete - new state:', this._connection.state);
            }
        } catch (error) {
            Sentry.captureException(error, {
                extra: { connectionState: this._connection?.state }
            });
        }
    };

    /**
     * When a message is received from the underlying HubConnection, dispatch the event to the EventTarget
     *
     * @param type - The WebSocketEventType for this event
     * @param data - The payload data from the socket
     */
    private _handleMessage(message: { type: WebSocketEventType, data: any }) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(message.type, message.data));
    }

    /**
     * Wraps the given WebSocketSubscriptionCallback to call it with the data from the WebSocketEvent
     *
     * @param callback - The WebSocketSubscriptionCallback to wrap
     */
    private _wrapCallback<TData>(callback: WebSocketSubscriptionCallback<TData>): EventListener {
        return (event) => {
            if (event instanceof WebSocketEvent) { // Should always be true, but necessary for TS
                callback(event.data);
            }
        };
    }

    /**
     * Opens the underlying websocket
     */
    async open() {
        if (this._connection && this._connection.state === HubConnectionState.Disconnected) {
            await this._connection.start();
        }
    }

    /**
     * Closes the underlying websocket
     */
    close() {
        window.removeEventListener('focus', this._handleWindowFocus);
        this._connection?.stop();
    }

    /**
     * Overload for user notification events
     */
    public subscribe(
        type: WebSocketEventType.USER_NOTIFICATION,
        callback: WebSocketSubscriptionCallback<Notification>
    ): () => void;

    /**
     * Overload for file upload complete notification events
     */
    public subscribe(
        type: WebSocketEventType.FILE_UPLOAD_COMPLETE,
        callback: WebSocketSubscriptionCallback<FileUpload>
    ): () => void;

    /**
     * Overload for pricing result events
     */
    public subscribe(
        type: WebSocketEventType.PRICING_COMPLETE,
        callback: WebSocketSubscriptionCallback<BatchPricingResults>
    ): () => void;

    /**
     * Overload for batch upload complete
     */
    public subscribe(
        type: WebSocketEventType.BATCH_UPLOAD_COMPLETE,
        callback: WebSocketSubscriptionCallback<PricingUploadBatch>
    ): () => void;

    /**
     * Subscribes to the given WebSocketEventType. Overloads for specific WebSocketEventTypes should be
     * defined above, hence the `never` argument passed to the actual implementation.
     *
     * @param   type     - The WebSocketEventType to subscribe to
     * @param   callback - The event listener callback
     * @returns an unsubscribe function
     */
    public subscribe(
        type: WebSocketEventType,
        callback: WebSocketSubscriptionCallback<never>
    ) {
        const wrappedCallback = this._wrapCallback(callback);

        this._eventTarget.addEventListener(type, wrappedCallback);

        return () => {
            this._eventTarget.removeEventListener(type, wrappedCallback);
        };
    }

    public simulateNotification() {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.USER_NOTIFICATION, {
            id: `${this._mockNotificationId++}`,
            type: NotificationType.LOAN_ROLE_ASSIGNMENT,
            description: 'Some description',
            loanNumber: '123456789',
            createdDate: new Date().toISOString(),
            isRead: false
        }));
    }

    public simulatePricingComplete(pricingResults?: BatchPricingResults) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.PRICING_COMPLETE, pricingResults));
    }

    public simulateRepricingComplete(repricedLoan: LoanPricingResult) {
        const repricingResult: BatchPricingResults = {
            batchId: `repricing-${repricedLoan.loanId}`,
            results: [ repricedLoan ]
        };
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.PRICING_COMPLETE, repricingResult));
    }

    public simulateUploadComplete(file: FileUpload) {
        this._eventTarget.dispatchEvent(new WebSocketEvent(WebSocketEventType.FILE_UPLOAD_COMPLETE, file));
    }
}
