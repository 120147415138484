import api, {
    AmortizationType, LoanDetail, amortizationTypeDisplay, automatedUwRecommendationDisplay,
    automatedUwSystemDisplay, documentationTypeDisplay, loanPurposeDisplay, loanTypeDisplay,
    occupancyTypeDisplay, propertyTypeDisplay
} from '@api';
import {
    Button, DialogContent, Divider, FormControl, FormLabel, MenuItem, Typography
} from '@mui/material';
import {
    AddressFieldset,
    CurrencyField,
    DateField,
    DialogActions,
    PercentField,
    RoutedDialog,
    Switch,
    TextField,
    isAtLeast,
    renderEnumOptions,
    usePageMessage
} from '@tsp-ui/core/components';
import utilStyles from '@tsp-ui/core/sass/style-utils.module.scss';
import { toSentenceCase, useGetCurrentAccount, useRenderTogglableEnumOptions } from '@utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import styles from './EditLoanDialog.module.scss';


enum EncompassHMDAFederalAgency {
    OCC = 'OCC',
    FRS = 'FRS',
    FDIC = 'FDIC',
    NCUA = 'NCUA',
    HUD = 'HUD',
    CFPB = 'CFPB'
}

export function EditLoanDialog() {
    const [ loading, setLoading ] = useState(false);
    const { setLoanDetail, loanDetail } = useContext(LoanDetailContext);
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const navigate = useNavigate();
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    const formMethods = useForm<LoanDetail>({ defaultValues: loanDetail });

    const handleSubmit = formMethods.handleSubmit(async (data) => {
        setLoading(true);

        try {
            setLoanDetail(await api.loans.updateLoan(clientId, loanDetail!.id, data, customerId));

            pageMessage.success('Loan updated');

            navigate('..');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating loan data', error);
        }

        setLoading(false);
    });

    const amortizationType = formMethods.watch('amortizationType');
    const coborrowerFlag = formMethods.watch('coborrowerFlag');

    return (
        <RoutedDialog
            closeTo=".."
            title="Edit loan data"
            maxWidth="md"
        >
            <DialogContent>
                <FormProvider {...formMethods}>
                    <form
                        id="loan-data-form"
                        onSubmit={handleSubmit}
                        className={styles.root}
                        noValidate
                    >
                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Loan information
                        </Typography>

                        <TextField<LoanDetail>
                            name="loanNumber"
                            label="Loan number"
                            required
                            disabled
                        />

                        <CurrencyField<LoanDetail>
                            name="loanAmount"
                            label="Loan amount"
                            required
                        />

                        <DateField<LoanDetail>
                            name="expirationDate"
                            label="Expiration date"
                        />

                        <PercentField<LoanDetail>
                            name="interestRate"
                            label="Interest rate"
                            type="number"
                            required
                            noConversion
                        />

                        <TextField<LoanDetail>
                            name="loanType"
                            label="Loan type"
                            required
                            select
                        >
                            {renderEnumOptions(loanTypeDisplay)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="loanTerm"
                            label="Loan term"
                            type="number"
                        />

                        <TextField<LoanDetail>
                            name="lockPeriod"
                            label="Lock period"
                            type="number"
                        />

                        <TextField<LoanDetail>
                            name="amortizationType"
                            label="Amortization type"
                            required
                            select
                        >
                            {renderEnumOptions(amortizationTypeDisplay)}
                        </TextField>

                        {amortizationType === AmortizationType.ADJUSTABLE && (
                            <FormControl
                                className={utilStyles.fullWidth}
                                component="fieldset"
                            >
                                <FormLabel
                                    component="legend"
                                    required
                                >
                                    Amortization details
                                </FormLabel>

                                <div className={styles.root}>
                                    <CurrencyField<LoanDetail>
                                        name="armMargin"
                                        label="Margin"
                                        size="small"
                                        required
                                        hideRequiredIndicator
                                        rules={isAtLeast(0)}
                                    />

                                    <CurrencyField<LoanDetail>
                                        name="armLifeCap"
                                        label="Life cap"
                                        size="small"
                                        required
                                        hideRequiredIndicator
                                        rules={isAtLeast(0)}
                                    />
                                </div>
                            </FormControl>
                        )}

                        <Switch<LoanDetail>
                            name="interestOnlyFlag"
                            label="Interest only flag"
                        />

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Borrowers
                        </Typography>

                        <BorrowerRow />

                        <Switch<LoanDetail>
                            name="coborrowerFlag"
                            label="Coborrower flag"
                            className={utilStyles.fullWidth}
                        />

                        {coborrowerFlag && (
                            <BorrowerRow isCoborrower />
                        )}

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Property details
                        </Typography>

                        <TextField<LoanDetail>
                            name="propertyType"
                            label="Property type"
                            select
                        >
                            {renderEnumOptions(propertyTypeDisplay)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="units"
                            label="Units"
                            select
                        >
                            {renderTogglableEnumOptions('numUnits')}
                        </TextField>

                        <TextField<LoanDetail>
                            name="occupancy"
                            label="Occupancy"
                            select
                        >
                            {renderEnumOptions(occupancyTypeDisplay)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="purpose"
                            label="Purpose"
                            select
                        >
                            {renderEnumOptions(loanPurposeDisplay)}
                        </TextField>

                        <CurrencyField<LoanDetail>
                            name="appraisedValue"
                            label="Appraised value"
                        />

                        <AddressFieldset<LoanDetail>
                            className={clsx(utilStyles.fullWidth, styles.address)}
                            fieldNames={{
                                street: 'address.street',
                                city: 'address.city',
                                state: 'address.state',
                                zip: 'address.zip'
                            }}
                        />

                        <Divider className={utilStyles.fullWidth} />

                        <Typography
                            className={utilStyles.fullWidth}
                            color="textSecondary"
                        >
                            Additional data
                        </Typography>

                        <TextField<LoanDetail>
                            name="specialtyProgram"
                            label="Specialty program"
                            select
                        >
                            {renderTogglableEnumOptions('specialtyProgram')}
                        </TextField>

                        <TextField<LoanDetail>
                            name="documentationType"
                            label="Documentation type"
                            select
                        >
                            {renderEnumOptions(documentationTypeDisplay)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="limitedLiabilityCorp"
                            label="Limited liability corp" // TODO is it correct to be using the EncompassHMDAFederalAgency?
                            select
                        >
                            {autoRenderOptions(EncompassHMDAFederalAgency)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="mortgageInsCompany"
                            label="Mortgage ins company"
                        />

                        <TextField<LoanDetail>
                            name="automatedUwSystem"
                            label="Automated UW system"
                            select
                        >
                            {renderEnumOptions(automatedUwSystemDisplay)}
                        </TextField>

                        <TextField<LoanDetail>
                            name="automatedUwRecommendation"
                            label="Automated UW recommendation"
                            select
                        >
                            {renderEnumOptions(automatedUwRecommendationDisplay)}
                        </TextField>

                        <Switch<LoanDetail>
                            name="mortgageInsFlag"
                            label="Mortgage insurance flag"
                            type="checkbox"
                        />
                    </form>
                </FormProvider>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    form="loan-data-form"
                    color="primary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

function BorrowerRow({ isCoborrower = false }: {isCoborrower?: boolean}) {
    const borrowerIndex = isCoborrower ? 1 : 0;
    const borrowerText = isCoborrower ? 'Coborrower' : 'Borrower';

    return (
        <span className={clsx(utilStyles.fullWidth, styles.borrowerRow)}>
            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.firstName`}
                label={`${borrowerText} first name`}
                required
            />

            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.middleName`}
                label={`${borrowerText} middle name`}
            />

            <TextField<LoanDetail>
                name={`borrowers.${borrowerIndex}.lastName`}
                label={`${borrowerText} last name`}
                required
            />
        </span>
    );
}

// Temporary solution until we implement mapping from Encompass values to internal enums
function autoRenderOptions(enumObj: Record<string, string>) {
    return Object.entries(enumObj).map(([ value, _ ]) => (
        <MenuItem
            value={value}
            key={value}
        >
            {toSentenceCase(value)}
        </MenuItem>
    )).filter(Boolean);
}
