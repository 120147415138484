import api, {
    LoanDocument, LoanEvent, LoanPricingResult,
    LoanRegistrationParams,
    PermissionType, RegisteredLoan, RegistrationType
} from '@api';
import {
    Check,
    CheckCircleOutline, Difference, Edit, HourglassBottom, Lock,
    Pending, PriceCheck
} from '@mui/icons-material';
import {
    Button, Grow, LinearProgress, MenuItem, Paper, Popover, Tooltip, Typography
} from '@mui/material';
import { PaginatedResponse } from '@tsp-ui/core';
import {
    FileInput, FilledSection, IconButton, IconTypography, LabeledValue
} from '@tsp-ui/core/components';
import {
    isPastDate, replaceItemById, useAsyncEffect, usePageMessage
} from '@tsp-ui/core/utils';
import { tooltipTitle, useGetCurrentAccount, useHasPermission } from '@utils';
import { LoanPricingResultDetails } from '@views/product-pricing/components/LoanPricingResultCard';
import PricingExpiredButton from '@views/product-pricing/components/PricingExpiredButton';
import { formatDistanceToNowStrict, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useContext, useEffect, useState
} from 'react';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { LoansContext } from '../LoansPage';

import { LoanCard } from './LoanCard';
import { LoanTimeline } from './LoanTimeline';
import styles from './RegisteredLoanCard.module.scss';


interface RegisteredLoanCardProps {
    loan: RegisteredLoan;
    loanPricingResults?: PaginatedResponse<LoanPricingResult>;
    updateLoanPricingResults?: Dispatch<SetStateAction<PaginatedResponse<LoanPricingResult>>>;
    updateFloatedLoans?: () => void;
    showPricingResults?: boolean;
    setLoans?: Dispatch<SetStateAction<PaginatedResponse<RegisteredLoan>>>;
    isProductAndPricingPage?: boolean;
    currentNoteRates?: number[];
    refreshPricingResults?(): Promise<void>;
}

export default function RegisteredLoanCard({
    loan, loanPricingResults, setLoans, updateLoanPricingResults,
    updateFloatedLoans, showPricingResults, isProductAndPricingPage, currentNoteRates, refreshPricingResults
}: RegisteredLoanCardProps) {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const { isLockDeskOpen } = useContext(LoansContext);

    const {
        loanStatus, expirationDate, id: loanId, pricingResultId, interestRate
    } = loan;

    const [ loanDocuments, setLoanDocuments ] = useState<LoanDocument[]>();
    const [ pricingResult, setPricingResult ] = useState<LoanPricingResult | null>(null);
    const [ docsLoading, setDocsLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => { // get loan data for card
        try {
            setLoanDocuments(
                (await api.loans.document.getLoanDocuments(
                    clientId, loanId, customerId
                )).filter(({ instanceCount }) => instanceCount === 1)
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }

        setDocsLoading(false);
    }, [
        clientId, customerId, loanId, pageMessage
    ]));

    const loanPricingResultsData = loanPricingResults?.data;

    useEffect(() => {
        if (!pricingResult && showPricingResults) {
            const result = loanPricingResultsData?.find(({ loanId }) => (
                loanId === pricingResultId
            ));

            if (result) {
                setPricingResult(result);
            }
        }
    }, [
        pricingResult, loanId, pricingResultId, loanPricingResultsData, showPricingResults
    ]);

    const [ selectedNoteRate, setSelectedNoteRate ] = useState<number | null>(null);

    useEffect(() => {
        if (interestRate !== undefined && selectedNoteRate === null) {
            setSelectedNoteRate(interestRate);
        }
    }, [ interestRate, selectedNoteRate ]);

    return (
        <LoanCard
            className={styles.root}
            loan={loan}
            isPendingLoan={false}
            isProductAndPricingPage={isProductAndPricingPage}
            currentNoteRates={currentNoteRates}
            selectedNoteRate={selectedNoteRate}
            setSelectedNoteRate={setSelectedNoteRate}
            numDocuments={loanDocuments?.length}
            docsLoading={docsLoading}
            expandedContent={showPricingResults ? (
                <LoanPricingResultDetails
                    loanId={loanId}
                    pricingResultId={pricingResult?.id || ''}
                    updateLoanPricingResults={updateLoanPricingResults}
                    updateFloatedLoans={updateFloatedLoans}
                    isExpired={new Date(pricingResult?.expirationDate || '') < new Date()}
                    interestRate={interestRate || 0}
                    selectedNoteRate={selectedNoteRate}
                    refreshPricingResults={refreshPricingResults}
                />
            ) : (
                <LoanCardContent
                    loan={loan}
                    setLoans={setLoans}
                    loanDocuments={loanDocuments}
                    setLoanDocuments={setLoanDocuments}
                />
            )}
            additionalDetails={(
                <div className={styles.additionalDetails}>
                    <div>
                        <Typography
                            variant="caption"
                            align="right"
                            component="div"
                        >
                            {
                                /* TODO post-demo hard coded for now in lieu of fetching a real
                                    status update after a document upload */
                                loanStatus
                            }
                        </Typography>

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            component="div"
                            align="right"
                        >
                            {isPastDate(expirationDate)
                                ? 'Lock expired'
                                : expirationDate
                                    ? `Lock expires in ${formatDistanceToNowStrict(parseISO(expirationDate))}`
                                    : 'Not Locked'}
                        </Typography>
                    </div>

                    {isPastDate(expirationDate) ? (
                        <PricingExpiredButton
                            expirationDate={expirationDate || 'N/A'}
                            loanId={loanId}
                            updateLoanPricingResults={updateLoanPricingResults}
                            updateFloatedLoans={updateFloatedLoans}
                        />
                    ) : loan.registrationType === RegistrationType.FLOAT ? (
                        <IconButton
                            disabled={!isLockDeskOpen}
                            tooltip={isLockDeskOpen ? 'Lock loan' : 'Cannot lock loan while the lock desk is closed'}
                            onClick={async () => {
                                try {
                                    /**
                                     * TODO: We need to add loanPricingProductId and pricingResultId to the Loan table
                                     * to link floated loans to their respective product and pricing result. Then we can
                                     * update the RegisteredLoan interface to include these fields.
                                     */
                                    await api.loans.registerLoan(clientId, loan.id, {
                                        registrationType: RegistrationType.LOCK,
                                        productId: (loan as RegisteredLoan & { productId: string }).productId,
                                        pricingResultId: (loan as RegisteredLoan & { pricingResultId: string }).pricingResultId || '1'
                                    } as LoanRegistrationParams, customerId);
                                } catch (error) {
                                    pageMessage.handleApiError('An error occurred while locking the loan', error);
                                }
                            }}
                        >
                            <Lock
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    ) : (
                        <IconButton tooltip="View pricing info">
                            <PriceCheck
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>
                    )}
                </div>
            )}
        />
    );
}

interface LoanCardContentProps {
    loan: RegisteredLoan;
    setLoans: Dispatch<SetStateAction<PaginatedResponse<RegisteredLoan>>> | undefined;
    loanDocuments?: LoanDocument[];
    setLoanDocuments: Dispatch<SetStateAction<LoanDocument[] | undefined>>;
}

function LoanCardContent({
    loan, setLoans, setLoanDocuments, loanDocuments = []
}: LoanCardContentProps) {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    const {
        losLoanStatuses, loanStatusConfigs, availableDocs = [], requiredDocCodes = []
    } = useContext(LoansContext);

    const loanStatusId = losLoanStatuses.find(status => status.name === loan.loanStatus)?.id;
    const loanStatusConfig = loanStatusConfigs.find(config => config.losLoanStatusIds.includes(loanStatusId!));
    const docsRequired = loanStatusConfig?.displayOrder === 1;

    const { id } = loan;

    const [ loading, setLoading ] = useState(false);

    // TODO post-demo implement the actual workflow for uploading additional docs
    const [ addAnotherDoc, setAddAnotherDoc ] = useState(false);

    const [ fileAdded, setFileAdded ] = useState(false);
    const [ indexingComplete, setIndexingComplete ] = useState(false);
    const [ debouncedIndexingComplete ] = useDebounce(indexingComplete, 1000);
    const fileLoading = fileAdded && !indexingComplete;

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement>();
    const [ eventsLoading, setEventsLoading ] = useState(true);

    const [ loanEvents, setLoanEvents ] = useState<LoanEvent[]>([]);
    const [ loanTimelineError, setLoanTimelineError ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        try {
            setLoanEvents(await api.loans.getLoanEvents(clientId, id, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching the loan timeline', error);
            setLoanTimelineError(true);
        }

        setEventsLoading(false);
    }, [
        clientId, id, customerId, pageMessage
    ]));

    async function handleUpload(files: File[]) {
        setFileAdded(true);

        const formData = new FormData();
        formData.append('file', files[0]);

        try {
            await api.loans.document.uploadLoanDocument(clientId, loan.id, formData, customerId);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while uploading a loan document', error);
        }

        setIndexingComplete(true);
    }

    async function onUploadComplete() {
        setLoanDocuments(await api.loans.document.getLoanDocuments(clientId, loan.id, customerId));
        setLoanEvents(await api.loans.getLoanEvents(clientId, loan.id, customerId));
        setIndexingComplete(true);

        setLoans?.(await api.loans.getRegisteredLoans(clientId, customerId));
    }

    const handleStatusUpdate = async (statusId: string) => {
        setLoading(true);

        try {
            const updatedLoan = await api.loans.updateLoanStatus(clientId, id, statusId);
            setLoans?.((loans) => ({
                ...loans,
                data: replaceItemById(loans.data, updatedLoan)
            }));

            pageMessage.success('Loan status updated');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while updating the loan status', error);
        }

        setAnchorEl(undefined);
        setLoading(false);
    };

    const [ canEditLoanStatus ] = useHasPermission([ PermissionType.EDIT_LOAN_STATUS ]);

    const requiredDocs = availableDocs
        .filter(doc => requiredDocCodes.includes(doc.code))
        .map(doc => {
            const uploadedDoc = loanDocuments.find(uploadedDoc => uploadedDoc.name === doc.name);
            return {
                name: doc.name,
                code: doc.code,
                isUploaded: !!uploadedDoc,
                isReviewed: uploadedDoc?.isReviewed || false,
                uploadedDoc
            };
        });

    return !docsRequired ? null : (
        <div className={styles.expandableContent}>
            <div className={styles.column}>
                <Paper
                    elevation={0}
                    className={styles.editPaper}
                >
                    <div>
                        <LabeledValue
                            label="Loan status"
                            value={loan.loanStatus}
                            variants={{ value: 'body1' }}
                            variant="vertical"
                        />
                    </div>

                    <IconButton
                        tooltip={canEditLoanStatus ? 'Update loan status' : 'You do not have permission to edit the loan status'}
                        className={styles.loanStatusButton}
                        disabled={!canEditLoanStatus || loading}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                        <Edit color="secondary" />
                    </IconButton>

                    <Popover
                        open={!!anchorEl}
                        onClose={() => setAnchorEl(undefined)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                    >
                        {losLoanStatuses
                            ?.filter(status => status.name !== loan.loanStatus)
                            .map(status => (
                                <MenuItem
                                    key={status.id}
                                    value={status.id}
                                    onClick={() => handleStatusUpdate(status.id)}
                                >
                                    {status.name}
                                </MenuItem>
                            ))}
                    </Popover>
                </Paper>

                <LoanTimeline
                    variant="section"
                    loanEvents={loanEvents}
                    isAwaitingDocs={loanDocuments.length === 0 && !fileAdded}
                    isIndexing={fileLoading}
                    isLoading={eventsLoading}
                    isInProgress={
                        (fileAdded && debouncedIndexingComplete) || (!fileAdded && loanDocuments.length > 0)
                    }
                    isError={loanTimelineError}
                />
            </div>

            {!addAnotherDoc && (loanDocuments.length > 0 || indexingComplete) ? (
                <Grow
                    in
                    timeout={1500}
                >
                    <div className={styles.fileUploadContainer}>
                        <CheckCircleOutline
                            fontSize="large"
                            color="success"
                        />

                        <Typography>
                            Initial doc package uploaded
                        </Typography>

                        <Button
                            className={styles.addAnotherButton}
                            onClick={() => setAddAnotherDoc(true)}
                        >
                            Add another document
                        </Button>
                    </div>
                </Grow>
            ) : (
                <div className={styles.fileUploadContainer}>
                    <Typography>
                        {addAnotherDoc ? 'Upload an additional document' : 'Upload the initial doc package for this loan'}
                    </Typography>

                    <div className={styles.fileInputContainer}>
                        <FileInput
                            title="file"
                            acceptedFileTypes={fileTypes}
                            disabled={fileLoading}
                            single // TODO post-demo change this back to accept multiple
                            onAddFiles={newFiles => {
                                if (newFiles.length) {
                                    handleUpload(newFiles);

                                    // TODO post-demo remove mock
                                    // api.webSocket.subscribe('UPLOAD_COMPLETE', onUploadComplete);
                                    setTimeout(onUploadComplete, 7000);
                                }
                            }}
                        />

                        {fileLoading && (
                            <div className={styles.fileUploadLoader}>
                                <LinearProgress />
                            </div>
                        )}
                    </div>
                </div>
            )}

            <FilledSection
                className={styles.section}
                noResultsMessage="No required documents are currently configured."
                header="Required Documents"
                headerTypographyProps={{
                    variant: 'caption',
                    color: 'textSecondary'
                }}
            >
                {requiredDocs.map(doc => (
                    <Paper
                        key={doc.name}
                        className={styles.docContainer}
                        elevation={0}
                    >
                        <Tooltip title={tooltipTitle({
                            'ADE Data Applied': doc.isReviewed,
                            'Pending approval': doc.isUploaded && !doc.isReviewed,
                            'Document needed': !doc.isUploaded
                        })}
                        >
                            <IconTypography
                                icon={doc.isReviewed ? (
                                    <Check color="success" />
                                ) : doc.isUploaded ? (
                                    <HourglassBottom color="primary" />
                                ) : (
                                    <Pending color="action" />
                                )}
                                variant="body2"
                                fontWeight={400}
                            >
                                {doc.name}
                            </IconTypography>
                        </Tooltip>

                        {(doc.isReviewed || doc.isUploaded) && (
                            <IconButton
                                tooltip="Review ADE Data"
                                component={Link}
                                to={`${id}/loan-data/document/${doc.uploadedDoc?.id}`}
                                size="small"
                                className={styles.adeIconButton}
                            >
                                <Difference color="success" />
                            </IconButton>
                        )}
                    </Paper>
                ))}
            </FilledSection>
        </div>
    );
}

const fileTypes = [ 'pdf' ];
