export enum LoanEventType {
    CREATED = 'CREATED',
    PRICED = 'PRICED',
    REPRICED = 'REPRICED',
    LOCKED = 'LOCKED',
    INITIAL_DOC_PACKAGE_UPLOADED = 'INITIAL_DOC_PACKAGE_UPLOADED',
    DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
    SETUP_COMPLETE = 'SETUP_COMPLETE',
    UPDATED = 'UPDATED',
    FLOATED = 'FLOATED'
}

export const loanEventTypeDisplay = {
    [LoanEventType.CREATED]: 'Loan created',
    [LoanEventType.PRICED]: 'Loan priced',
    [LoanEventType.REPRICED]: 'Loan repriced',
    [LoanEventType.LOCKED]: 'Loan locked',
    [LoanEventType.INITIAL_DOC_PACKAGE_UPLOADED]: 'Initial doc package uploaded',
    [LoanEventType.DOCUMENT_UPLOADED]: 'Document uploaded',
    [LoanEventType.SETUP_COMPLETE]: 'Setup complete',
    [LoanEventType.UPDATED]: 'Updated',
    [LoanEventType.FLOATED]: 'Floated'
};
