export enum FeeType {
    '203K_SUPPLEMENTAL_FEE' = '203K_SUPPLEMENTAL_FEE',
    ADMINISTRATION_FEE = 'ADMINISTRATION_FEE',
    CDFI_CREDIT = 'CDFI_CREDIT',
    CODE_COMPLIANCE_FEE = 'CODE_COMPLIANCE_FEE',
    COMPLIANCE_FEE = 'COMPLIANCE_FEE',
    CONDO_REVIEW = 'CONDO_REVIEW',
    DOCUMENT_FEE = 'DOCUMENT_FEE',
    DPA_REIMBURSED_BY_TSACH = 'DPA_REIMBURSED_BY_TSACH',
    DPA_REIMBURSEMENT = 'DPA_REIMBURSEMENT',
    EARLY_RENO_REVIEW_FEE = 'EARLY_RENO_REVIEW_FEE',
    ELECTRONIC_UPLOAD_FEE = 'ELECTRONIC_UPLOAD_FEE',
    FHA_MANUAL_UW = 'FHA_MANUAL_UW',
    FLOOD_CERT_FEE = 'FLOOD_CERT_FEE',
    FLOOD_DETERMINATION = 'FLOOD_DETERMINATION',
    FLOOD_TRANSFER_FEE = 'FLOOD_TRANSFER_FEE',
    FUNDING_FEE = 'FUNDING_FEE',
    LENDER_FEE = 'LENDER_FEE',
    LOCK_PURCHASE_EXTENSION_FEE = 'LOCK_PURCHASE_EXTENSION_FEE',
    MCC_FEE = 'MCC_FEE',
    MERS_FEE = 'MERS_FEE',
    OTHER = 'OTHER',
    PIA_FEE = 'PIA_FEE',
    PIW_FEE = 'PIW_FEE',
    PREMIER_EVALUATING_FEE = 'PREMIER_EVALUATING_FEE',
    PROGRAM_APPLICATION_FEE = 'PROGRAM_APPLICATION_FEE',
    REFINOW_APPRAISAL_FEE = 'REFINOW_APPRAISAL_FEE',
    REFIPOSSIBLE_APPRAISAL_FEE = 'REFIPOSSIBLE_APPRAISAL_FEE',
    SBHL_FEE = 'SBHL_FEE',
    SPECIAL_PROGRAM_CREDIT = 'SPECIAL_PROGRAM_CREDIT',
    SUPPLEMENTAL_ORIGINATION_FEE = 'SUPPLEMENTAL_ORIGINATION_FEE',
    SUSPENSE_FEE = 'SUSPENSE_FEE',
    TAX_SERVICE_FEE = 'TAX_SERVICE_FEE',
    TEXAS_DPA_FEE = 'TEXAS_DPA_FEE',
    TX50A6 = 'TX50A6',
    UFMIP_FEE = 'UFMIP_FEE',
    UNDERWRITING_AND_PURCHASE_FEE = 'UNDERWRITING_AND_PURCHASE_FEE',
    UNDERWRITING_FEE = 'UNDERWRITING_FEE',
    VA_FUNDING_FEE = 'VA_FUNDING_FEE'
}

export const feeTypeDisplay = {
    [FeeType['203K_SUPPLEMENTAL_FEE']]: '203k supplemental Fee',
    [FeeType.ADMINISTRATION_FEE]: 'Administration Fee',
    [FeeType.CDFI_CREDIT]: 'Cdfi credit',
    [FeeType.CODE_COMPLIANCE_FEE]: 'Code compliance Fee',
    [FeeType.COMPLIANCE_FEE]: 'Compliance Fee',
    [FeeType.CONDO_REVIEW]: 'Condo review',
    [FeeType.DOCUMENT_FEE]: 'Document Fee',
    [FeeType.DPA_REIMBURSED_BY_TSACH]: 'Dpa reimbursed by tsach',
    [FeeType.DPA_REIMBURSEMENT]: 'Dpa reimbursement',
    [FeeType.EARLY_RENO_REVIEW_FEE]: 'Early reno review Fee',
    [FeeType.ELECTRONIC_UPLOAD_FEE]: 'Electronic upload Fee',
    [FeeType.FHA_MANUAL_UW]: 'Fha manual uw',
    [FeeType.FLOOD_CERT_FEE]: 'Flood cert Fee',
    [FeeType.FLOOD_DETERMINATION]: 'Flood determination',
    [FeeType.FLOOD_TRANSFER_FEE]: 'Flood transfer Fee',
    [FeeType.FUNDING_FEE]: 'Funding Fee',
    [FeeType.LENDER_FEE]: 'Lender Fee',
    [FeeType.LOCK_PURCHASE_EXTENSION_FEE]: 'Lock purchase extension Fee',
    [FeeType.MCC_FEE]: 'Mcc Fee',
    [FeeType.MERS_FEE]: 'Mers Fee',
    [FeeType.OTHER]: 'Other',
    [FeeType.PIA_FEE]: 'Pia Fee',
    [FeeType.PIW_FEE]: 'Piw Fee',
    [FeeType.PREMIER_EVALUATING_FEE]: 'Premier evaluating Fee',
    [FeeType.PROGRAM_APPLICATION_FEE]: 'Program application Fee',
    [FeeType.REFINOW_APPRAISAL_FEE]: 'Refinow appraisal Fee',
    [FeeType.REFIPOSSIBLE_APPRAISAL_FEE]: 'Refipossible appraisal Fee',
    [FeeType.SBHL_FEE]: 'Sbhl Fee',
    [FeeType.SPECIAL_PROGRAM_CREDIT]: 'Special program credit',
    [FeeType.SUPPLEMENTAL_ORIGINATION_FEE]: 'Supplemental origination Fee',
    [FeeType.SUSPENSE_FEE]: 'Suspense Fee',
    [FeeType.TAX_SERVICE_FEE]: 'Tax service Fee',
    [FeeType.TEXAS_DPA_FEE]: 'Texas dpa Fee',
    [FeeType.TX50A6]: 'Tx50 a6',
    [FeeType.UFMIP_FEE]: 'Ufmip Fee',
    [FeeType.UNDERWRITING_AND_PURCHASE_FEE]: 'Underwriting and purchase Fee',
    [FeeType.UNDERWRITING_FEE]: 'Underwriting Fee',
    [FeeType.VA_FUNDING_FEE]: 'Va funding Fee'
};
