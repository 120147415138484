import {
    CreateLoanProgramReqBody,
    CreateLoanProgramResBody,
    EligibilityMatrix,
    InternalLoanProgram,
    Investor,
    InvestorStatusUpdateBody,
    LoanProgramStatusUpdateBody,
    ManagedEligibilityStatus
} from '@api';

import { apiUtils } from '../../api-utils';


/* Investor */
export function getInvestors(clientId: string, effectiveVersionId?: string): Promise<ClientInvestor[]> {
    return apiUtils.get(`/client/${clientId}/investor`, { effectiveVersionId });
}

export function getManagedInvestors(clientId: string): Promise<ClientInvestor[]> {
    return apiUtils.get(`/client/${clientId}/investor`, {
        managedEligibilityStatus: ManagedEligibilityStatus.MANAGED
    });
}

export function addInvestor(clientId: string, investor: Omit<ClientInvestor, 'id'>): Promise<ClientInvestor> {
    return apiUtils.post(`/client/${clientId}/investor`, investor);
}

export function updateInvestor(clientId: string, investor: ClientInvestor): Promise<ClientInvestor> {
    return apiUtils.put(`/client/${clientId}/investor/${investor.id}`, investor);
}

export function updateStatus(
    clientId: string, investorID: string, status: InvestorStatusUpdateBody
): Promise<ClientInvestor> {
    return apiUtils.patch(`/client/${clientId}/investor/${investorID}/status`, status);
}

export function activateManagedInvestor(
    clientId: string, payload: ActivateManagedInvestorPayload
): Promise<ClientInvestor> {
    return apiUtils.post(`/client/${clientId}/investor/managed`, payload);
}

/* Loan program */
export function getLoanPrograms(
    clientId: string, investorId?: string, effectiveVersionId?: string
): Promise<ClientLoanProgram[]> {
    return apiUtils.get(`/client/${clientId}${investorId ? `/investor/${investorId}` : ''}/loan-program`, { effectiveVersionId });
}

export function createLoanProgram(
    clientId: string, createLoanProgramBody: CreateLoanProgramReqBody
): Promise<CreateLoanProgramResBody> {
    const { investorId } = createLoanProgramBody.loanProgram;
    return apiUtils.post(`/client/${clientId}/investor/${investorId}/loan-program`, createLoanProgramBody);
}

export function updateLoanProgram(clientId: string, loanProgram: InternalLoanProgram): Promise<InternalLoanProgram> {
    const { id, investorId } = loanProgram;
    return apiUtils.put(`/client/${clientId}/investor/${investorId}/loan-program/${id}`, loanProgram);
}

export function updateLoanProgramStatus(
    clientId: string, investorId: string, loanProgramId: string, status: LoanProgramStatusUpdateBody
): Promise<InternalLoanProgram> {
    return apiUtils.patch(`/client/${clientId}/investor/${investorId}/loan-program/${loanProgramId}/status`, status);
}

/* Eligibility */
export function getEligibilityMatrix(
    clientId: string, investorID: string, loanProgramID: string, effectiveVersionId?: string
) {
    return apiUtils.get(`/client/${clientId}/investor/${investorID}/loan-program/${loanProgramID}/matrix`, {
        effectiveVersionId
    });
}

export function updateEligibilityMatrix(
    clientId: string, investorID: string, loanProgramID: string, matrix: EligibilityMatrix
) {
    return apiUtils.put(`/client/${clientId}/investor/${investorID}/loan-program/${loanProgramID}/matrix`, matrix);
}

export type ClientInvestor = Omit<Investor, 'activeClientIds'> & {
    sellerServicerNumber?: string;
    eligibilityBasedOnInvestorId?: string;
}

export interface ActivateManagedInvestorPayload {
    investorId: string;
    sellerServicerNumber: string;
    eligibilityVersionId: string;
}

export interface ClientLoanProgram extends InternalLoanProgram {
    baseLoanProgramId: string | undefined;
}
