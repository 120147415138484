import api, { Notification, WebSocketEventType } from '@api';
import { AssignmentTurnedIn, Notifications } from '@mui/icons-material';
import {
    Badge, Button, Divider, Grow, IconButton, Popover, Tooltip, Typography
} from '@mui/material';
import { removeItemById, replaceItemById, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import NotificationHistoryDialog
    from '@views/components/MainNav/components/NotificationsButton/components/NotificationHistoryDialog';
import { parseISO } from 'date-fns';
import {
    useCallback, useEffect, useMemo, useState
} from 'react';
import { TransitionGroup } from 'react-transition-group';

import styles from './NotificationsButton.module.scss';
import NewNotificationDisplay from './components/NewNotificationDisplay';
import { NotificationCard } from './components/NotificationCard';


export default function NotificationsButton() {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ notifications, setNotifications ] = useState<Notification[]>([]);
    const [ newNotification, setNewNotification ] = useState<Notification>();

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    const pageMessage = usePageMessage();

    useEffect(() => api.webSocket.subscribe(
        WebSocketEventType.USER_NOTIFICATION,
        (notification) => {
            setNotifications((notifications) => ([ notification, ...notifications ]));

            if (!anchorEl) { // Don't show the new notification indicator if the notifications popover is already open
                setNewNotification(notification);
            }
        }
    ), [ anchorEl ]);

    // useAsyncEffect(useCallback(async () => {
    //     try {
    //         setNotifications((await api.notifications.getUnreadNotifications()).data);
    //     } catch (error) {
    //         pageMessage.handleApiError('An error occurred while fetching unread notifications', error);
    //     }
    // }, [ pageMessage ]));

    const markNotificationsAsViewed = useCallback(async () => {
        try {
            // Mark as viewed first so if it fails, the notification will be updated for this session at least
            setNotifications(notifications.map(notification => (({
                ...notification,
                isViewed: true
            }))));

            await Promise.all(
                notifications.filter(({ isViewed }) => !isViewed).map(
                    ({ id }) => api.notifications.updateNotification(clientId, id, { isViewed: true }, customerId)
                )
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while marking notifications as viewed. You may see these notifications again in the future.', error);
        }
    }, [
        notifications, clientId, customerId, pageMessage
    ]);

    // All the following functions are using useCallback to make sure the reference is stable so the
    // NotificationCards rendered by the NotificationHistoryDialog don't rerender when scrolling the dialog
    const updateNotificationRead = useCallback(async (notification: Notification, isRead: boolean) => {
        try {
            await api.notifications.updateNotification(clientId, notification.id, {
                isRead,
                ...(isRead && {
                    isViewed: true
                })
            }, customerId);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while marking the notification as read', error);
        }
    }, [
        clientId, customerId, pageMessage
    ]);

    const markNotificationAsRead = useCallback(async (notification: Notification) => {
        // Remove the notification first so if it fails, the notification will be gone for this session at least
        // setNotifications(removeItemById(notifications, notification.id));
        setNotifications((notifications) => removeItemById(notifications, notification.id));

        await updateNotificationRead(notification, true);
    }, [ updateNotificationRead ]);

    const markNotificationAsUnread = useCallback(async (notification: Notification) => {
        // Remove the notification first so if it fails, the notification will be gone for this session at least
        setNotifications((notifications) => {
            const updatedNotification = {
                ...notification,
                isRead: false
            };

            return notifications.find(({ id }) => notification.id === id)
                ? replaceItemById(notifications, updatedNotification)
                : [ ...notifications, updatedNotification ];
        });

        await updateNotificationRead(notification, false);
    }, [ updateNotificationRead ]);

    const dialogNotificationOnLinkClick = useCallback(async (notification: Notification) => {
        setDialogOpen(false);

        await markNotificationAsRead(notification);
        await markNotificationsAsViewed();
    }, [ markNotificationAsRead, markNotificationsAsViewed ]);

    const showHistoryButton = (
        <Button
            onClick={() => {
                setDialogOpen(true);
                setAnchorEl(undefined);
            }}
        >
            View all notifications
        </Button>
    );

    const sortedNotifications = useMemo(() => notifications.sort((a, b) => (
        parseISO(b.createdDate).getTime() - parseISO(a.createdDate).getTime()
    )), [ notifications ]);

    return (
        <>
            <NewNotificationDisplay
                notification={newNotification}
                onDismiss={markNotificationAsRead}
            />

            <Tooltip title="Notifications">
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setNewNotification(undefined);
                    }}
                >
                    <Badge
                        badgeContent={notifications.filter(({ isViewed }) => !isViewed).length}
                        color="error"
                    >
                        <Notifications color="secondary" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <NotificationHistoryDialog
                open={dialogOpen}
                onClose={async () => {
                    setDialogOpen(false);
                    await markNotificationsAsViewed();
                }}
                onLinkClick={dialogNotificationOnLinkClick}
                onMarkRead={markNotificationAsRead}
                onMarkUnread={markNotificationAsUnread}
            />

            <Popover
                open={!!anchorEl}
                onClose={async () => {
                    setAnchorEl(undefined);
                    await markNotificationsAsViewed();
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{ className: styles.notificationsMenu }}
            >
                <Typography variant="h6">
                    Notifications
                </Typography>

                {!notifications.length ? (
                    <Grow in>
                        <Typography className={styles.upToDate}>
                            <AssignmentTurnedIn
                                color="primary"
                                fontSize="large"
                            />

                            You don't have any unread notifications

                            {showHistoryButton}
                        </Typography>
                    </Grow>
                ) : (
                    <>
                        <TransitionGroup
                            className={styles.notifications}
                            appear={!anchorEl}
                        >
                            {sortedNotifications.map((notification) => (
                                <NotificationCard
                                    key={notification.id}
                                    onLinkClick={async () => {
                                        setAnchorEl(undefined);
                                        await markNotificationAsRead(notification);
                                    }}
                                    notification={notification}
                                    onDismiss={markNotificationAsRead}
                                />
                            ))}
                        </TransitionGroup>

                        <Divider />

                        {showHistoryButton}
                    </>
                )}
            </Popover>
        </>
    );
}
