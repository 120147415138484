import {
    SpecialtyProgram, loanPurposeDisplay, occupancyTypeDisplay, propertyTypeDisplay
} from '@api';
import { Edit } from '@mui/icons-material';
import {
    Divider, IconButton, Link, Paper, Tooltip, Typography
} from '@mui/material';
import { AddressTypography, LabelGroup, LabeledValue } from '@tsp-ui/core';
import { formatCurrency } from '@tsp-ui/core/utils';
import { LoanDetailContext } from '@views/loans/LoanDetailPage';
import clsx from 'clsx';
import { useContext } from 'react';

import styles from './AUSTable.module.scss';


const labeledValueProps = {
    variants: {
        label: 'body2',
        value: 'body1'
    }
} as const;

const verticalLabeledValueProps = {
    ...labeledValueProps,
    variant: 'vertical'
} as const;

export function AusTable() {
    const { loanDetail } = useContext(LoanDetailContext);

    return (
        <Paper
            variant="outlined"
            className={styles.paper}
        >
            <div className={clsx(styles.fullWidth, styles.ausDetailsHeader)}>
                <Typography
                    className={styles.ausTypeChip}
                    color="textSecondary"
                    fontWeight={500}
                >
                    Fannie Mae DU
                </Typography>

                <Typography fontWeight={500}>
                    {loanDetail?.loanNumber}{' '}

                    <Typography
                        component="span"
                        color="textSecondary"
                    >
                        — submission{' '}

                        <Tooltip title="Submission date: 11/22/2022 04:30PM">
                            <Link underline="always">#1</Link>
                        </Tooltip>
                    </Typography>
                </Typography>

                <Typography
                    color="green"
                    fontWeight={500}
                    className={styles.statusText}
                >
                    Approve/Eligible
                </Typography>

                <IconButton edge="end">
                    <Edit color="secondary" />
                </IconButton>
            </div>

            <div className={clsx(styles.section, styles.loanDetailsSection)}>
                <Typography className={styles.header}>
                    Loan details
                </Typography>

                <div>
                    <Typography fontWeight={500}>
                        {formatCurrency(loanDetail?.loanAmount)}
                    </Typography>

                    <Typography>
                        <span className={styles.hidden}>$</span>{/*

                        */}{loanDetail?.interestRate?.toFixed(2)}%
                    </Typography>

                    <Typography>
                        <span className={styles.hidden}>$</span>{/*
                        */}{loanDetail?.loanTerm} mo
                    </Typography>
                </div>

                <LabelGroup>
                    <LabeledValue
                        {...labeledValueProps}
                        label="LTV:"
                        value={`${(100 * ((loanDetail?.loanAmount ?? 0) / (loanDetail?.salePrice ?? 0))).toFixed(2)}%`}
                    />

                    <LabeledValue
                        {...labeledValueProps}
                        label="CLTV:"
                        value={`${(100 * ((loanDetail?.loanAmount ?? 0) / (loanDetail?.salePrice ?? 0))).toFixed(2)}%`}
                    />

                    <LabeledValue
                        {...labeledValueProps}
                        label="HCLTV:"
                        value={`${(100 * ((loanDetail?.loanAmount ?? 0) / (loanDetail?.salePrice ?? 0))).toFixed(2)}%`}
                    />
                </LabelGroup>

                <LabelGroup>
                    <LabeledValue
                        {...labeledValueProps}
                        label="Front DTI:"
                        value="15.26%"
                    />

                    <LabeledValue
                        {...labeledValueProps}
                        label="Back DTI:"
                        value="39.79%"
                    />
                </LabelGroup>

                <Divider className={styles.divider} />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Loan purpose"
                    value={loanDetail?.purpose && loanPurposeDisplay[loanDetail?.purpose]}
                />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Occupancy"
                    value={loanDetail?.occupancy && occupancyTypeDisplay[loanDetail?.occupancy]}
                />

                {loanDetail?.specialtyProgram === SpecialtyProgram.HOME_READY && (
                    <LabeledValue
                        {...verticalLabeledValueProps}
                        label="Community lending"
                        value="Home ready"
                    />
                )}

                <Typography className={styles.header}>
                    Property
                </Typography>

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Purchase Price"
                    value={formatCurrency(loanDetail?.salePrice)}
                />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    className={styles.address}
                    label="Property address"
                    value={!loanDetail?.address ? 'None' : (
                        <AddressTypography
                            variant="inherit"
                            address={loanDetail?.address}
                        />
                    )}
                />
            </div>

            <div className={styles.section}>
                <Typography className={styles.header}>
                    Appraisal
                </Typography>

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Required appraisal type"
                    value="Full appraisal"
                />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Appraised value"
                    value={formatCurrency(loanDetail?.appraisedValue)}
                />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Property type"
                    value={loanDetail?.propertyType && propertyTypeDisplay[loanDetail?.propertyType]}
                />

                <Typography className={styles.header}>
                    Asset requirements
                </Typography>

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Required reserves"
                    value="$10,000"
                />

                <LabeledValue
                    {...verticalLabeledValueProps}
                    label="Statements required"
                    value="2"
                />

                <Typography className={styles.header}>
                    Credit report numbers (last 4 digits)
                </Typography>

                {loanDetail?.borrowers.map((borrower) => (
                    <LabelGroup>
                        <LabeledValue
                            {...labeledValueProps}
                            label={`${borrower.firstName} ${borrower.lastName}`}
                            value="1234"
                        />
                    </LabelGroup>
                ))}
            </div>
        </Paper>
    );
}
