import api, { LoanComment, PermissionType } from '@api';
import {
    CancelOutlined, Edit, RemoveCircleOutline, SaveOutlined
} from '@mui/icons-material';
import { Paper, Tooltip, Typography } from '@mui/material';
import {
    Button,
    CardDialogContent,
    DialogActions,
    IconButton,
    RoutedDialog,
    RoutedDialogImplProps,
    TextField,
    replaceItemById,
    useAsyncEffect,
    useConfirm,
    usePageMessage
} from '@tsp-ui/core';
import { formatDistanceToNowStrict } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHasPermission } from '@utils';
import UserLink from '@views/admin/users/UserLink';
import { ActiveTokenContext } from '@views/components/ActiveTokenContext';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, useCallback, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ClientTokenPayload, decodeAuthToken } from '../../../api/api-utils';
import { LoanDetailContext } from '../LoanDetailPage';
import { PendingLoanDetailContext } from '../PendingLoanDetailPage';

import styles from './LoanCommentsDialog.module.scss';


const MAX_COMMENT_LENGTH = 250;

export function LoanCommentsDialog(props: RoutedDialogImplProps) {
    const loanDetailContext = useContext(LoanDetailContext);
    const pendingLoanDetailContext = useContext(PendingLoanDetailContext);
    const activeContext = loanDetailContext.loanDetail ? loanDetailContext : pendingLoanDetailContext;

    const {
        loanDetail, comments, setComments, refreshComments
    } = activeContext;

    // gets current userId for conditional rendering
    const { activeAuthToken } = useContext(ActiveTokenContext);
    const { user_id: currentUserId } = decodeAuthToken<ClientTokenPayload>(activeAuthToken);

    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);
    const pageMessage = usePageMessage();

    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_COMMENTS ]);

    const formMethods = useForm<LoanComment>(undefined);
    const { watch } = formMethods;
    const commentField = watch('comment');
    const remainingChars = MAX_COMMENT_LENGTH - (commentField?.length || 0);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setSaveLoading(true);

        try {
            setComments([
                ...comments, await api.loans.createLoanComment(
                    clientId, loanDetail!.id, formData, customerId
                )
            ]);
            formMethods.resetField('comment');

            pageMessage.success('Comment saved');
            await refreshComments();
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the comment', error);
        }

        setSaveLoading(false);
    });

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setComments(await api.loans.getLoanComments(clientId, loanDetail!.id, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching comments', error);
        }

        setLoading(false);
    }, [
        setComments, clientId, loanDetail, customerId, pageMessage
    ]));

    return (
        <RoutedDialog
            {...props}
            title="Loan Comments"
            maxWidth={false}
            loading={loading}
            classes={{ paper: styles.dialogRoot }}
        >
            <CardDialogContent className={styles.content}>
                {comments.length > 0
                    ? comments.map(comment => (
                        <LoanCommentCard
                            key={comment.id}
                            comment={comment}
                            currentUserId={currentUserId}
                            setComments={setComments}
                        />
                    )) : (
                        <Typography className={styles.noComments}>No comments</Typography>
                    )}
            </CardDialogContent>

            <DialogActions
                className={styles.dialogActions}
                loading={saveLoading}
            >
                <FormProvider {...formMethods}>
                    <form
                        id="add-loan-comment-form"
                        onSubmit={handleSubmit}
                        className={styles.form}
                    >
                        <TextField<LoanComment>
                            fullWidth
                            maxLength={MAX_COMMENT_LENGTH}
                            multiline
                            name="comment"
                            label="Enter comment"
                            helperText={`${remainingChars}/250 characters`}
                        />
                    </form>
                </FormProvider>

                <Button
                    type="submit"
                    form="add-loan-comment-form"
                    variant="contained"
                    disabled={saveLoading || !canManage || !commentField}
                    tooltip={canManage ? ''  : 'You do not have permission to add comments'}
                >
                    Send
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

interface LoanCommentCardProps {
    comment: LoanComment;
    currentUserId: string;
    setComments: Dispatch<SetStateAction<LoanComment[]>>;
}

function LoanCommentCard({
    comment, currentUserId, setComments
}: LoanCommentCardProps) {
    const [ isEditing, setIsEditing ] = useState(false);
    const [ deleteLoading, setDeleteLoading ] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    const isCurrentUser = currentUserId === comment.creatorUserId;
    const { id: clientId, customerId } = useGetCurrentAccount();

    const formattedLastModifiedDate = comment.lastModifiedDate
        ? format(parseISO(comment.lastModifiedDate), 'MM/dd/yyyy h:mm a')
        : '';

    const createdDate = parseISO(comment.createdDate);
    const createdDateTooltip = format(createdDate, 'MM/dd/yyyy h:mm a');
    const formattedCreateTime = formatDistanceToNowStrict(createdDate, {
        addSuffix: true
    });

    const { loanId, id } = comment;

    const formMethods = useForm<LoanComment>({
        defaultValues: comment
    });

    const [ canManage ] = useHasPermission([ PermissionType.MANAGE_LOAN_COMMENTS ]);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        try {
            const updatedComment = await api.loans.updateLoanComment(
                clientId, loanId, id, formData, customerId
            );
            setComments(comments => replaceItemById(comments, updatedComment));

            formMethods.resetField('comment');

            pageMessage.success('Comment saved');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while saving the comment', error);
        }

        setIsEditing(false);
        setLoading(false);
    });

    async function handleDelete() {
        if (id && await confirm('Are you sure you want to delete this comment?')) {
            try {
                setDeleteLoading(true);

                await api.loans.deleteLoanComment(clientId, loanId, id, customerId);
                setComments(await api.loans.getLoanComments(
                    clientId, loanId, customerId
                ));

                pageMessage.success('Comment deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the comment', error);
            }

            setDeleteLoading(false);
        }
    }

    return (
        <div className={clsx(styles.cardWrapper, {
            [styles.cardWrapperFromCurrentUser]: isCurrentUser
        })}
        >
            {!isCurrentUser && (
                <UserLink
                    userId={comment.creatorUserId!}
                    showAvatar
                />
            )}

            <Paper
                variant="outlined"
                className={clsx(styles.comment, {
                    [styles.commentFromCurrentUser]: isCurrentUser
                })}
            >
                <div className={styles.commentContent}>
                    {isEditing ? (
                        <FormProvider {...formMethods}>
                            <form
                                id="edit-loan-comment-form"
                                onSubmit={handleSubmit}
                            >
                                <TextField<LoanComment>
                                    variant="standard"
                                    InputProps={{ classes: { root: styles.inlineFormTextField } }}
                                    maxLength={250}
                                    maxRows={2}
                                    multiline
                                    name="comment"
                                    defaultValue={comment.comment}
                                />

                                <IconButton
                                    edge="end"
                                    size="small"
                                    type="submit"
                                    tooltip="Done"
                                    loading={loading}
                                >
                                    <SaveOutlined
                                        color="secondary"
                                        classes={{ root: styles.button }}
                                    />
                                </IconButton>

                                <IconButton
                                    edge="end"
                                    size="small"
                                    tooltip="Close"
                                    onClick={() => setIsEditing(false)}
                                >
                                    <CancelOutlined
                                        color="error"
                                        classes={{ root: styles.button }}
                                    />
                                </IconButton>
                            </form>
                        </FormProvider>

                    ) : (
                        <div className={styles.descriptionButtonContainer}>
                            <Typography
                                variant="body2"
                                className={styles.description}
                            >
                                {comment.comment}
                            </Typography>

                            {canManage && isCurrentUser && (
                                <>
                                    <IconButton
                                        size="small"
                                        tooltip="Edit comment"
                                        disabled={isEditing}
                                        onClick={() => setIsEditing(true)}
                                    >
                                        <Edit
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </IconButton>

                                    <IconButton
                                        edge="end"
                                        size="small"
                                        tooltip="Delete comment"
                                        onClick={handleDelete}
                                        loading={deleteLoading}
                                    >
                                        <RemoveCircleOutline
                                            color="error"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </>
                            )}
                        </div>
                    )}

                    <div className={styles.createdAt}>
                        {formattedLastModifiedDate && (
                            <Tooltip title={`Edited ${formattedLastModifiedDate}`}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    (edited)
                                </Typography>
                            </Tooltip>
                        )}

                        <Tooltip title={`Created ${createdDateTooltip}`}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {formattedCreateTime}
                            </Typography>
                        </Tooltip>
                    </div>
                </div>
            </Paper>
        </div>
    );
}
