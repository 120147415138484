import api, {
    EncompassUnderwritingCondition,
    LoanPricingResult, LoanStatusConfig, LoanSummary, LoanWithActivity, PermissionType,
    RegisteredLoan,
    loanTypeDisplay
} from '@api';
import { Settings } from '@mui/icons-material';
import {
    Avatar, Button, Divider, IconButton, Link as MuiLink, Tooltip, Typography,
    useTheme
} from '@mui/material';
import {
    RoutedDialogManager, usePageMessage
} from '@tsp-ui/core/components';
import { formatCurrency, formatCurrencyAbbreviation, useAsyncEffect } from '@tsp-ui/core/utils';
import { getGradient, useGetCurrentAccount, withAuth } from '@utils';
import { AlertSection } from '@views/components/AlertSection';
import LoanNumberLink from '@views/components/MainNav/components/NotificationsButton/components/LoanNumberLink';
import { LoanCard } from '@views/loans/components/LoanCard';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import LoanNumberAutoComplete from '@views/loans/components/LoanNumberAutoComplete';
import { LoanSectionBase } from '@views/loans/components/LoanSectionBase';
import { RecentLoanCard } from '@views/loans/components/RecentLoanCard';
import {
    ArcElement, ChartData, Chart as ChartJS, ChartOptions, Tooltip as ChartTooltip, Legend
// @ts-ignore
} from 'chart.js';
import React, {
    ReactNode,
    useCallback,
    useMemo,
    useState
} from 'react';
// @ts-ignore
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import { getMockedPricingResult } from '../../api/pricing/pricing-mocks';
import Page from '../components/Page';

import styles from './AccountDashboardPage.module.scss';


const results: LoanPricingResult[] = [
    getMockedPricingResult('Jim Burrows'),
    getMockedPricingResult('John Burrows'),
    getMockedPricingResult('Jeff Burrows', true)
].reverse();

export default function AccountDashboardPage() {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const theme = useTheme();

    const [ recentLoans, setRecentLoans ] = useState<LoanWithActivity[]>([]);
    const [ loans, setLoans ] = useState<RegisteredLoan[]>([]);
    const [ loanSummaries, setLoanSummaries ] = useState<LoanSummary[]>([]);
    const [ loanStatusConfigs, setLoanStatusConfigs ] = useState<LoanStatusConfig[]>([]);
    const [ loanConditions, setLoanConditions ] = useState<Record<string, EncompassUnderwritingCondition[]>>({});
    const [ loanNumberSearch, setLoanNumberSearch ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const loadLoanConditions = useCallback(async (loans: RegisteredLoan[]) => {
        const conditionsMap: Record<string, EncompassUnderwritingCondition[]> = Object.create(null);
        const results = await Promise.all(loans.map(async loan => {
            try {
                const conditions = await api.loans.getUnderwritingConditions(
                    clientId, customerId, loan.losLoanId || ''
                );

                return {
                    losLoanId: loan.losLoanId,
                    conditions
                };
            } catch (error) {
                console.log(error);
                return {
                    losLoanId: loan.losLoanId,
                    conditions: []
                };
            }
        }));

        results.forEach(({ losLoanId, conditions }) => {
            if (losLoanId) {
                conditionsMap[losLoanId] = conditions;
            }
        });

        return conditionsMap;
    }, [ clientId, customerId ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        try {
            const [
                recentLoans, summaries, configs, loans
            ] = await Promise.all([
                api.loans.getRecentLoans(clientId, customerId),
                api.loans.getLoansSummary(clientId, customerId),
                api.loanStatus.getLoanStatusConfigs(clientId),
                api.loans.getRegisteredLoans(clientId, customerId, {
                    pageSize: 500, // TODO: post-demo clean up to do this on the back end
                    pageNumber: 1
                })
            ]);

            setRecentLoans(recentLoans);
            setLoanSummaries(summaries);
            setLoanStatusConfigs(configs.sort((a, b) => a.displayOrder - b.displayOrder));
            setLoans(loans.data || []);

            setLoanConditions(await loadLoanConditions(loans.data));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching data', error);
        }
        setLoading(false);
    }, [
        clientId, customerId, loadLoanConditions, pageMessage
    ]));

    const pipelineData = useMemo(() => loanStatusConfigs.map(config => ({
        title: config.title,
        value: loanSummaries.find(s => s.statusConfigId === config.id)?.loanCount || 0
    })), [ loanStatusConfigs, loanSummaries ]);

    const conditionsData = useMemo(() => {
        const conditionCounts = Object.values(loanConditions)
            .map(conditions => conditions.filter(c => !c.isCleared && !c.isWaived && !c.isRemoved).length);

        const countMap = conditionCounts.reduce((acc, count) => {
            if (count > 0) {
                if (count >= 4) {
                    // Group all counts of 4 or more together
                    acc['4+'] = (acc['4+'] || 0) + 1;
                } else {
                    acc[count] = (acc[count] || 0) + 1;
                }
            }
            return acc;
        }, {} as Record<string | number, number>);

        return Object.entries(countMap)
            .map(([ count, loans ]) => ({
                title: `${count} condition${count === '1' ? '' : 's'} outstanding`,
                value: loans
            }))
            .sort((a, b) => {
                // Custom sort to handle the "4+" category
                const aCount = a.title.startsWith('4+') ? 4 : parseInt(a.title);
                const bCount = b.title.startsWith('4+') ? 4 : parseInt(b.title);
                return aCount - bCount;
            });
    }, [ loanConditions ]);

    const volumeData = useMemo(() => {
        const volumeByType = loans?.reduce((acc, loan) => {
            if (loan.loanAmount && loan.loanType) {
                const typeDisplay = loanTypeDisplay[loan.loanType];
                acc[typeDisplay] = (acc[typeDisplay] || 0) + loan.loanAmount;
            }
            return acc;
        }, {} as Record<string, number>) || {};

        return Object.entries(volumeByType)
            .map(([ title, value ]) => ({
                title,
                value
            }))
            .sort((a, b) => b.value - a.value);
    }, [ loans ]);

    const colors = getGradient(theme.palette.primary.main, 3)?.map(color => color.toHexString()) || [];

    return (
        <Page
            className={styles.main}
            loading={loading}
            header={(
                <div className={styles.pageHeader}>
                    Dashboard

                    <LoanNumberAutoComplete
                        className={styles.autocomplete}
                        loanNumberSearch={loanNumberSearch}
                        setLoanNumberSearch={setLoanNumberSearch}
                        renderAsLinks
                    />
                </div>
            )}
            headerActions={(
                <IconButton onClick={() => api.webSocket.simulateNotification()}>
                    <Settings color="secondary" />
                </IconButton>
            )}
        >
            <div className={styles.root}>
                <div className={styles.mainContent}>
                    <div className={styles.charts}>
                        <ChartSection
                            title="Loans by pipeline stage"
                            data={pipelineData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans in pipeline"
                        />

                        <ChartSection
                            title="Loans by conditions outstanding"
                            data={conditionsData}
                            renderValue={(value) => value.toString()}
                            totalLabel="loans with conditions outstanding"
                        />

                        <ChartSection
                            title="Current volume by product type"
                            data={volumeData}
                            renderValue={(value) => (
                                <AbbreviatedCurrencyDisplay value={value} />
                            )}
                            totalLabel="total volume"
                            type="volume"
                        />
                    </div>

                    <LoanSectionBase
                        paginatedResponse={{
                            data: results,
                            pageNumber: 1,
                            pageSize: results.length,
                            totalPages: 1,
                            totalRecords: results.length
                        }}
                        setPageNumber={() => {}}
                        defaultExpand
                        disableExpand={false}
                        title="Watched Loans"
                        loading={loading}
                        loanCount={undefined}
                        noResultsMessage={(
                            <>
                                Your watched loans will appear here!<br />

                                <>
                                    <MuiLink
                                        component={Link}
                                        to="../loans"
                                    >
                                        Go to the loans page
                                    </MuiLink> to get started.
                                </>
                            </>
                        )}
                    >
                        {results?.map((result) => (
                            <LoanCard
                                isPendingLoan
                                loanPricingResult={result}
                                className={styles.loanRow}
                            />
                        ))}
                    </LoanSectionBase>

                    <LoanSectionBase
                        paginatedResponse={{
                            data: recentLoans,
                            pageNumber: 1,
                            pageSize: recentLoans.length,
                            totalPages: 1,
                            totalRecords: recentLoans.length
                        }}
                        setPageNumber={() => {}}
                        defaultExpand
                        disableExpand={false}
                        title="Recent Loans"
                        loading={loading}
                        loanCount={undefined}
                        noResultsMessage={(
                            <>
                                Your recently viewed loans will appear here!<br />

                                <>
                                    <MuiLink
                                        component={Link}
                                        to="../loans"
                                    >
                                        Go to the loans page
                                    </MuiLink> to get started.
                                </>
                            </>
                        )}
                    >
                        {recentLoans?.map((recentLoan) => (
                            <RecentLoanCard
                                key={recentLoan.id}
                                loan={recentLoan}
                            />
                        ))}
                    </LoanSectionBase>
                </div>

                <div className={styles.sidebar}>
                    {customerId && <AlertSection />}

                    <div className={styles.sidebar}>
                        <div>
                            <Typography
                                fontWeight={500}
                                className={styles.header}
                            >
                                Activity stream
                            </Typography>

                            <div className={styles.activityStream}>
                                <ActivityStreamItem
                                    avatarColor={colors[0]}
                                    initials="MO"
                                    loanNumber="7543672"
                                    dateText="5 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Awaiting Docs & Setup</b>

                                            {' '}to

                                            {' '}<b>Underwriting</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor={colors[1]}
                                    initials="JK"
                                    loanNumber="7543671"
                                    dateText="15 minutes ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor={colors[2]}
                                    initials="NP"
                                    loanNumber="7543672"
                                    dateText="45 minutes ago"
                                    message="Loan document uploaded"
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor={colors[1]}
                                    initials="JK"
                                    loanNumber="7543670"
                                    dateText="1 hour ago"
                                    message={(
                                        <>
                                            <b>Loan status</b>

                                            {' '}changed from

                                            {' '}<b>Underwriting</b>

                                            {' '}to

                                            {' '}<b>Clear to close</b>
                                        </>
                                    )}
                                />

                                <Divider className={styles.divider} />

                                <ActivityStreamItem
                                    avatarColor={colors[0]}
                                    initials="MO"
                                    loanNumber="7543670"
                                    dateText="2 hours ago"
                                    message="Loan locked"
                                />

                                <Divider className={styles.divider} />

                                <Button size="small">Load more activity</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RoutedDialogManager routes={dialogRoutes} />
        </Page>
    );
}

const dialogRoutes = {
    ':loanID/loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    ':loanID/loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true)
};

interface ActivityStreamItemProps {
    avatarColor: string;
    initials: string;
    message: ReactNode;
    loanNumber: string;
    dateText: string;
}

function ActivityStreamItem({
    avatarColor, initials, message, loanNumber, dateText
}:ActivityStreamItemProps) {
    return (
        <div className={styles.activityStreamItem}>
            <Avatar sx={{ bgcolor: avatarColor }}>
                {initials}
            </Avatar>

            <div>
                <Typography
                    variant="body2"
                    className={styles.activityStreamMessage}
                >
                    {message}
                </Typography>

                <div className={styles.activityStreamBottomRow}>
                    <LoanNumberLink
                        color="textPrimary"
                        loanNumber={loanNumber}
                        loanId="0"
                    />

                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {dateText}
                    </Typography>
                </div>
            </div>
        </div>
    );
}

interface ChartSectionProps {
    title: ReactNode;
    data: Array<{
        title: string;
        value: number;
    }>;
    renderValue: (value: number) => ReactNode;
    totalLabel: ReactNode;
    type?: 'volume' | 'count';
}

ChartJS.register(ArcElement, ChartTooltip, Legend);

function ChartSection({
    title,
    data,
    renderValue,
    totalLabel,
    type = 'count'
}: ChartSectionProps) {
    const theme = useTheme();
    const total = data.reduce((sum, v) => sum + v.value, 0);
    const colors = getGradient(theme.palette.primary.main, data.length)?.map(color => color.toHexString()) || [];

    const chartData: ChartData<'doughnut'> = {
        labels: data.map(d => d.title),
        datasets: [
            {
                data: data.map(d => d.value),
                backgroundColor: colors,
                borderColor: 'white',
                borderWidth: 2.5,
                hoverOffset: 4,
                hoverBackgroundColor: '#2E7D32',
                borderRadius: 4
            }
        ]
    };

    // Create a string version of the renderValue result
    function getTooltipLabel(value: number) {
        const element = renderValue(value);

        if (React.isValidElement(element)) {
            return formatCurrencyAbbreviation(value);
        }

        return element?.toString();
    }

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '75%',
        animation: {
            duration: 300
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                backgroundColor: 'black',
                bodyAlign: 'center',
                displayColors: false,
                callbacks: {
                    label: (context) => {
                        const value = context.raw as number;
                        const rawPercentage = (value / total) * 100;
                        const percentage = rawPercentage % 1 === 0
                            ? Math.round(rawPercentage) : rawPercentage.toFixed(1);

                        if (type === 'volume') {
                            return `${getTooltipLabel(value)} ${percentage}%`;
                        }

                        return `${getTooltipLabel(value)} loan${value > 1 ? 's' : ''} ${percentage}%`;
                    }
                }
            }
        }
    };

    return (
        <div>
            <Typography
                fontWeight={500}
                align="center"
            >
                {title}
            </Typography>

            <div className={styles.chartContainer}>
                <div className={styles.chartLabel}>
                    <Typography
                        color="textSecondary"
                        variant="h4"
                    >
                        {renderValue(total)}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                    >
                        {totalLabel}
                    </Typography>
                </div>

                <Doughnut
                    data={chartData}
                    options={options}
                />
            </div>
        </div>
    );
}

interface AbbreviatedCurrencyDisplayProps {
    value: number;
}

function AbbreviatedCurrencyDisplay({ value }: AbbreviatedCurrencyDisplayProps) {
    return (
        <Tooltip title={formatCurrency(value)}>
            <span>
                {formatCurrencyAbbreviation(value)}
            </span>
        </Tooltip>
    );
}
