import {
    Alert, AlertType, PermissionType, alertTypeDisplay
} from '@api';
import { OpenInNew } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { IconButton, ValueOf } from '@tsp-ui/core';
import { formatPastDate, useHasPermission } from '@utils';
import { Link } from 'react-router-dom';

import styles from './AlertCard.module.scss';


interface AlertCardProps {
    alert: Alert;
}

export function AlertCard({ alert }: AlertCardProps) {
    const { hasPermission, route } = useAlertRedirectRoute(alert.alertType);

    return (
        <Paper
            variant="outlined"
            className={styles.alertCard}
        >
            <Typography>
                {alertTypeDisplay[alert.alertType]}
            </Typography>

            <div className={styles.alertActions}>
                <div className={styles.alertIcons}>
                    {/* <Tooltip
                        title="This alert was sent with high importance"
                        enterDelay={0}
                    >
                        <PriorityHigh
                            color="warning"
                            fontSize="small"
                        />
                    </Tooltip> */}

                    <IconButton
                        disabled={!hasPermission}
                        component={Link}
                        to={route}
                        size="small"
                        tooltip={hasPermission ? 'View alert details' : `You do not have permission to ${alertPermissionTypeDisplay[alertTypeToPermission[alert.alertType]]}`}
                    >
                        <OpenInNew
                            color="secondary"
                            fontSize="small"
                        />
                    </IconButton>
                </div>

                <Typography
                    variant="caption"
                    color="textSecondary"
                    align="right"
                    component="div"
                    className={styles.date}
                >
                    {formatPastDate(alert.date)}
                </Typography>
            </div>
        </Paper>
    );
}

function useAlertRedirectRoute(alertType: AlertType): { hasPermission: boolean, route: string } {
    const [ hasPermission ] = useHasPermission([ alertTypeToPermission[alertType] ]);

    return {
        hasPermission,
        route: alertTypeToRoute[alertType]
    };
}

// TODO customers don't have access to most of these pages, this will be addressed in a future task
const alertTypeToRoute = {
    ELIGIBILITY: '../admin/investor-eligibility',
    REFERENCE_GUIDE: '../admin/reference-guides',
    ELIGIBLE_PRODUCT: '../admin/products', // or admin/customers/?/products ?
    LICENSE_EXPIRATION: '../admin/customers/:customerId/due-diligence', // customer will never be able to access this
    COMPENSATION_FEE: '../admin/customers/:customerId/compensation', // customer will never be able to access this
    LIMIT: '../admin/customers/:customerId/limits', // customer will never be able to access this
    LOCK_AVAILABILITY: '../admin/lock-availability' // customer will also never have access to this (logic is accountUserType === CLIENT && canViewLockAvailability in MainNav.tsx)
};

const alertTypeToPermission = {
    ELIGIBILITY: PermissionType.VIEW_ELIGIBILITY,
    LICENSE_EXPIRATION: PermissionType.VIEW_LICENSES,
    REFERENCE_GUIDE: PermissionType.VIEW_REFERENCE_GUIDES,
    LOCK_AVAILABILITY: PermissionType.VIEW_LOCK_AVAILABILITY,
    COMPENSATION_FEE: PermissionType.VIEW_FEE_COMPENSATION,
    ELIGIBLE_PRODUCT: PermissionType.VIEW_PRODUCTS,
    LIMIT: PermissionType.VIEW_LOAN_ALLOCATION_LIMITS
} as const;

export const alertPermissionTypeDisplay: Record<ValueOf<typeof alertTypeToPermission>, string> = {
    [PermissionType.VIEW_ELIGIBILITY]: 'view eligibility',
    [PermissionType.VIEW_LICENSES]: 'view licenses',
    [PermissionType.VIEW_REFERENCE_GUIDES]: 'view reference guides',
    [PermissionType.VIEW_LOCK_AVAILABILITY]: 'view lock availability',
    [PermissionType.VIEW_FEE_COMPENSATION]: 'view fees and compensation',
    [PermissionType.VIEW_PRODUCTS]: 'view products',
    [PermissionType.VIEW_LOAN_ALLOCATION_LIMITS]: 'view loan allocation limits'
};
