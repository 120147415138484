import { AlertType } from 'api/enums/alertTypeEnum';

import { apiUtils } from '../../api-utils';


export function getCustomerAlertConfigs(clientID: string): Promise<CustomerAlertConfig[]> {
    return apiUtils.get(`/client/${clientID}/customer-alert-config`);
}

export function deleteCustomerAlertConfig(clientID: string, alertConfigID: string): Promise<void> {
    return apiUtils.delete(`/client/${clientID}/customer-alert-config/${alertConfigID}`);
}

export function addCustomerAlertConfig(clientID: string, alertConfig: Omit<CustomerAlertConfig, 'id'>): Promise<CustomerAlertConfig> {
    return apiUtils.post(`/client/${clientID}/customer-alert-config`, alertConfig);
}

export function editCustomerAlertConfig(
    clientID: string, alertConfig: CustomerAlertConfig
): Promise<CustomerAlertConfig> {
    return apiUtils.put(`/client/${clientID}/customer-alert-config/${alertConfig.id}`, alertConfig);
}

export interface CustomerAlertConfig {
    id: string;
    type: AlertType;
    duration: number;
}
