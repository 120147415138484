import { ApprovalStatusEnum, approvalStatusDisplay } from '@api';
import { Error, PublishedWithChanges, Verified } from '@mui/icons-material';
import { ListItemButtonProps, Tooltip } from '@mui/material';
import { ListItemLink, ListItemLinkProps } from '@tsp-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import styles from './DataRequestsMenuListItem.module.scss';


interface IconMenuItemProps extends ListItemButtonProps {
    title: string;
    to?: ListItemLinkProps['to'];
    selected?: boolean;
    disableExtraWidth?: boolean;
    icon?: ReactNode;
}

export function IconMenuItem({
    to, selected, disableExtraWidth, icon, title, ...otherProps
}: DataRequestsMenuListItemProps) {
    return (
        <ListItemLink
            to={to ?? ''}
            selected={selected}
            className={styles.listItem}
            classes={{
                selected: clsx(styles.selected, {
                    [styles.extraWidth]: !disableExtraWidth
                })
            }}
            {...otherProps}
        >
            <span data-title={title}>
                {title}
            </span>

            {icon}
        </ListItemLink>
    );
}

interface DataRequestsMenuListItemProps extends IconMenuItemProps {
    tab?: string;
    status?: ApprovalStatusEnum;
}

export function DataRequestsMenuListItem({
    tab, status, selected, ...props
}: DataRequestsMenuListItemProps) {
    const { dataRequestID } = useParams();

    return (
        <IconMenuItem
            to={`${dataRequestID ? './../' : ''}${tab || ''}`}
            icon={(
                <Tooltip title={status ? approvalStatusDisplay[status] : ''}>
                    {status === 'SUBMITTED' ? (
                        <PublishedWithChanges fontSize="small" />
                    ) : status === 'APPROVED' ? (
                        <Verified
                            fontSize="small"
                            color="success"
                        />
                    ) : status === 'REJECTED' ? (
                        <Error
                            color="error"
                            fontSize="small"
                        />
                    ) : <span />}
                </Tooltip>
            )}
            selected={selected !== undefined ? selected : !!dataRequestID && dataRequestID === tab}
            {...props}
        />
    );
}
