import api, { BulkCommitment, Product } from '@api';
import { SentryRoutes } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount, useHandlePromiseSettledResult } from '@utils/hooks';
import { BulkCommitmentsContext, BulkCommitmentsContextValue } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import BulkCommitmentTrackingDetailPage from '@views/tracking/bulk-commitment/BulkCommitmentTrackingDetailPage';
import { useCallback, useMemo, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';

import BulkCommitmentTrackingPage from './bulk-commitment/BulkCommitmentTrackingPage';


// TODO post-demo withAuthentication
export default function TrackingRouteSwitch() {
    return (
        <SentryRoutes>
            <Route
                path="/"
                element={(
                    <Navigate to="bulk-commitments" />
                )}
            />

            <Route
                path="/bulk-commitments/*"
                element={<BulkCommitmentTrackingRoutes />}
            />
        </SentryRoutes>
    );
}

function BulkCommitmentTrackingRoutes() {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loading, setLoading ] = useState(true);
    const [ commitments, setCommitments ] = useState<BulkCommitment[]>([]);
    const [ products, setProducts ] = useState<Product[]>([]);

    const handlePromiseSettledResult = useHandlePromiseSettledResult();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoading(true);

            const [ commitmentsResult, productsResult ] = await Promise.allSettled([
                api.bulkCommitment.getCommitments(clientId, customerId),
                api.products.getAllProducts(clientId)
            ]);

            handlePromiseSettledResult(commitmentsResult, setCommitments, 'An error occurred while fetching commitments');
            handlePromiseSettledResult(productsResult, setProducts, 'An error occurred while fetching products');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching bulk commitments', error);
        } finally {
            setLoading(false);
        }
    }, [
        clientId, customerId, handlePromiseSettledResult, pageMessage
    ]));

    const contextValue = useMemo<BulkCommitmentsContextValue>(() => ({
        loading,
        bulkCommitments: commitments,
        setBulkCommitments: setCommitments,
        customers: [],
        products
    }), [
        loading, commitments, products, setCommitments
    ]);

    return (
        <BulkCommitmentsContext.Provider value={contextValue}>
            <SentryRoutes>
                <Route
                    path="/"
                    element={<BulkCommitmentTrackingPage />}
                />

                <Route
                    path="/:commitmentID/*"
                    element={<BulkCommitmentTrackingDetailPage />}
                />
            </SentryRoutes>
        </BulkCommitmentsContext.Provider>
    );
}
