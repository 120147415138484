import api, {
    EncompassUnderwritingCondition,
    LoanComment,
    LoanDetail,
    LoanEvent,
    PermissionType
} from '@api';
import {
    Comment, Description, Edit, OpenInNew, PendingActions
} from '@mui/icons-material';
import {
    Avatar, Badge, Button, ButtonBase, CircularProgress, Popover, Tooltip, Typography
} from '@mui/material';
import { ExpandableHeader, IconButton, RoutedDialogManager } from '@tsp-ui/core/components';
import {
    getFullName, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import {
    useGetCurrentAccount, useHandlePromiseSettledResult, useHasPermission, withAuth
} from '@utils';
import ContactDisplay from '@views/admin/customers/CustomerDetailPage/components/dialogs/ContactDisplay';
import LoanDataDialog from '@views/loans/components/LoanDataDialog';
import LoanDocumentsDialog from '@views/loans/components/LoanDocumentsDialog';
import {
    Dispatch, ReactNode, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Page from '../components/Page';

import styles from './LoanDetailPage.module.scss';
import { EditLoanDialog } from './components/EditLoanDialog';
import { LoanCommentsDialog } from './components/LoanCommentsDialog';
import { LoanTimeline } from './components/LoanTimeline';
import ConditionsSummary, { NoConditions } from './conditions/ConditionsSummary';
import { UnderwritingCategories } from './underwriting/UnderwritingCategories';
import { UnderwritingDocumentDialog } from './underwriting/UnderwritingDocumentDialog';


export interface LoanDetailPageParams {
    loanID: string;
    underwritingCategoryId: string;
}

export interface LoanDetailContextValue {
    loanDetail: LoanDetail | undefined;
    setLoanDetail: Dispatch<SetStateAction<LoanDetail | undefined>>;
    conditions: EncompassUnderwritingCondition[];
    setConditions: Dispatch<SetStateAction<EncompassUnderwritingCondition[]>>;
    comments: LoanComment[];
    setComments: Dispatch<SetStateAction<LoanComment[]>>;
    refreshComments: () => Promise<void>;
}

export const LoanDetailContext = createContext<LoanDetailContextValue>({
    conditions: [],
    setConditions: () => {},
    loanDetail: undefined,
    setLoanDetail: () => {},
    comments: [],
    setComments: () => {},
    refreshComments: async () => {}
});

/**
 * Renders the loan detail page
 *
 * @constructor
 */
export default function LoanDetailPage() {
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail>();
    const [ conditions, setConditions ] = useState<EncompassUnderwritingCondition[]>([]);
    const [ comments, setComments ] = useState<LoanComment[]>([]);
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);

    const { id: clientID, customerId } = useGetCurrentAccount();

    const { loanID } = useParams<LoanDetailPageParams>();
    const pageMessage = usePageMessage();
    const handlePromiseSettledResult = useHandlePromiseSettledResult();

    const [ assigneeAnchor, setAssigneeAnchor ] = useState<HTMLElement>();

    const conditionsByStatus = groupByConditionStatus(conditions);

    const [ canViewLoanConditions, canViewLoanComments ] = useHasPermission(
        [ PermissionType.VIEW_LOAN_CONDITIONS, PermissionType.VIEW_LOAN_COMMENTS ]
    );

    const refreshComments = useCallback(async () => {
        try {
            setComments(await api.loans.getLoanComments(clientID, loanID, customerId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan comments', error);
        }
    }, [
        clientID, loanID, customerId, pageMessage
    ]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [ loanDetailResult, commentsResult ] = await Promise.allSettled([
                api.loans.getLoanDetail(clientID, loanID, customerId),
                api.loans.getLoanComments(clientID, loanID, customerId)
            ]);
            handlePromiseSettledResult(loanDetailResult, setLoanDetail, 'An error occurred while fetching loan details');
            handlePromiseSettledResult(commentsResult, setComments, 'An error occurred while fetching loan comments');

            if (canViewLoanConditions && loanDetailResult?.status === 'fulfilled' && loanDetailResult.value.losLoanId) {
                const conditions = await api.loans.getUnderwritingConditions(
                    clientID, customerId, loanDetailResult.value.losLoanId
                );

                setConditions(conditions);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan details', error);
        }

        setLoading(false);
    }, [
        clientID, loanID, customerId, handlePromiseSettledResult, canViewLoanConditions, pageMessage
    ]));

    const contextValue = useMemo(() => ({
        conditions,
        setConditions,
        loanDetail,
        setLoanDetail,
        comments,
        setComments,
        refreshComments
    }), [
        comments, conditions, refreshComments, loanDetail
    ]);

    return (
        <LoanDetailContext.Provider value={contextValue}>
            <Page
                header={(loanDetail ? (
                    <div className={styles.header}>
                        Loan #{loanDetail.loanNumber}

                        <div>
                            <IconButton
                                title="View loan data"
                                component={Link}
                                to="loan-data"
                            >
                                <OpenInNew color="secondary" />
                            </IconButton>

                            <IconButton
                                title="Edit loan data"
                                component={Link}
                                to="edit"
                            >
                                <Edit color="secondary" />
                            </IconButton>

                            <IconButton
                                title="View loan documents"
                                component={Link}
                                to="loan-documents"
                                state={loanDetail.loanNumber}
                            >
                                <Description color="secondary" />
                            </IconButton>

                            <IconButton
                                tooltip="View loan comments"
                                disabled={!canViewLoanComments}
                                component={Link}
                                to="loan-comments"
                            >
                                <Badge
                                    badgeContent={comments.length}
                                    color="primary"
                                    invisible={comments.length === 0}
                                >
                                    <Comment color="secondary" />
                                </Badge>
                            </IconButton>
                        </div>
                    </div>
                ) : null)}
                headerActions={loading ? null : (
                    <>
                        <LoanStatusButton status={loanDetail?.loanStatus || ''} />

                        {loanDetail?.assignee ? (
                            <Tooltip
                                classes={{
                                    tooltip: styles.assignedToTooltip
                                }}
                                title={(
                                    <>
                                        Assigned to: {getFullName(loanDetail.assignee)}

                                        <br />
                                        Click for details
                                    </>
                                )}
                            >
                                <Avatar
                                    component={ButtonBase}
                                    className={styles.avatar}
                                    onClick={(event) => setAssigneeAnchor(
                                        event.currentTarget
                                    )}
                                >
                                    {`${loanDetail.assignee.firstName.charAt(0)}${loanDetail.assignee.lastName.charAt(0)}`}
                                </Avatar>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                classes={{
                                    tooltip: styles.assignedToTooltip
                                }}
                                title="Unassigned"
                            >
                                <Avatar className={styles.avatar} />
                            </Tooltip>
                        )}
                    </>
                )}
                loading={!loanDetail || loading}
                breadcrumbs={[
                    'Loans',
                    loanDetail?.loanNumber && `#${loanDetail?.loanNumber}`
                ]}
            >
                {customerId && ( // page content for customer user
                    <div className={styles.customerContent}>
                        <ConditionsPermissionCheck canView={canViewLoanConditions}>
                            {conditions.length === 0 ? (
                                <NoConditions />
                            ) : (
                                <div>
                                    {Object.entries(conditionsByStatus).map(([ group, conditions ]) => (
                                        <div
                                            className={styles.conditionsGroup}
                                            key={group}
                                        >
                                            <ExpandableHeader
                                                disableExpand={!conditions.length}
                                                defaultExpand={group === 'ConditionsNeedingAttention' && conditions.length > 0}
                                                title={group === 'Other' ? 'Other conditions' : 'Conditions needing attention'}
                                                secondaryText={`${conditions.length} condition${conditions.length !== 1 ? 's' : ''}`}
                                                expandedContent={conditions.length ? (
                                                    <TransitionGroup className={styles.conditions}>
                                                        <CSSTransition
                                                            key={group}
                                                            exit={false}
                                                            timeout={250}
                                                        >
                                                            <ConditionsSummary conditions={conditions} />
                                                        </CSSTransition>
                                                    </TransitionGroup>
                                                ) : null}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </ConditionsPermissionCheck>
                    </div>
                )}

                {!customerId && ( // page content for client user
                    <>
                        <UnderwritingCategories />

                        <UnderwritingDocumentDialog
                            open={open}
                            onClose={() => setOpen(false)}
                        />
                    </>
                )}

                <Popover
                    open={!!assigneeAnchor}
                    onClose={() => setAssigneeAnchor(undefined)}
                    anchorEl={assigneeAnchor}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom'
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                    }}
                    PaperProps={{
                        className: styles.popover
                    }}
                >
                    <ContactDisplay
                        contact={{
                            name: getFullName(loanDetail?.assignee),
                            phone: `${loanDetail?.assignee?.phone}`,
                            email: `${loanDetail?.assignee?.email}`
                        }}
                    />
                </Popover>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </LoanDetailContext.Provider>
    );
}

const dialogRoutes = {
    'loan-data': withAuth(LoanDataDialog, [ PermissionType.VIEW_LOANS ], true),
    'loan-documents': withAuth(LoanDocumentsDialog, [ PermissionType.VIEW_LOAN_DOCS ], true),
    edit: withAuth(EditLoanDialog, [ PermissionType.MANAGE_LOANS ], true),
    'loan-comments': withAuth(LoanCommentsDialog, [ PermissionType.VIEW_LOAN_COMMENTS ], true)
};

interface ConditionsPermissionCheckProps {
    canView: boolean;
    children: ReactNode;
}

function ConditionsPermissionCheck({ canView, children }: ConditionsPermissionCheckProps) {
    return canView ? children as JSX.Element : (
        <Typography>
            You do not have permission to view loan conditions
        </Typography>
    );
}

function groupByConditionStatus(conditions: EncompassUnderwritingCondition[]) {
    const groupedStatuses: Record<'ConditionsNeedingAttention' | 'Other', EncompassUnderwritingCondition[]> = {
        ConditionsNeedingAttention: [],
        Other: []
    };

    conditions.forEach((condition) => {
        const status: string = condition.status || '';
        const targetGroup = [
            'Requested', 'Rerequested', 'Added', 'Rejected'
        ].includes(status)
            ? 'ConditionsNeedingAttention'
            : 'Other';

        groupedStatuses[targetGroup].push(condition);
    });

    return groupedStatuses;
}

interface LoanStatusButtonProps {
    status: string;
    loanID?: string;
}

export function LoanStatusButton({ status, loanID: loanIdProp }: LoanStatusButtonProps) {
    const loanID = useParams<{ loanID: string }>().loanID || loanIdProp!;
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();

    const [ loanEvents, setLoanEvents ] = useState<LoanEvent[]>([]);
    const [ eventsError, setEventsError ] = useState(false);
    const [ eventsLoading, setEventsLoading ] = useState(false);
    const [ timelineAnchor, setTimelineAnchor ] = useState<HTMLElement>();

    async function openTimeline(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setTimelineAnchor(event.currentTarget);

        setEventsLoading(true);

        try {
            setLoanEvents(await api.loans.getLoanEvents(clientId, loanID, customerId));
        } catch (error) {
            setEventsError(true);
            pageMessage.handleApiError('An error occurred while fetching loan events', error);
        } finally {
            setEventsLoading(false);
        }
    }

    return (
        <>
            <Tooltip title="View loan timeline">
                <Button
                    variant="outlined"
                    startIcon={<PendingActions />} // TODO post-demo vary this icon by status type
                    onClick={openTimeline}
                >
                    {status}
                </Button>
            </Tooltip>

            <Popover
                open={!!timelineAnchor}
                onClose={() => setTimelineAnchor(undefined)}
                anchorEl={timelineAnchor}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
                PaperProps={{
                    className: styles.popover
                }}
            >
                {eventsLoading ? (
                    <div className={styles.timelineLoading}>
                        <CircularProgress
                            size={40}
                            color="primary"
                        />

                        <Typography
                            variant="body2"
                        >
                            Loading timeline...
                        </Typography>
                    </div>
                ) : (
                    <LoanTimeline
                        // This logic is going to have to be re-worked now that loan statuses are dynamic
                        variant="popover"
                        isAwaitingDocs={status === 'Ready for Docs'} // TODO do not hardcode
                        isInProgress={status === 'Started'} // TODO do not hardcode
                        loanEvents={loanEvents}
                        isLoading={eventsLoading}
                        isError={eventsError}
                    />
                )}
            </Popover>
        </>
    );
}
