import api, {
    LockAvailability, LockAvailabilityOverride, LockSuspension, TimeString
} from '@api';
import { RoutedDialogManager } from '@tsp-ui/core/components';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils/hooks';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import Page from '@views/components/Page';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';

import { EditLockAvailabilityDialog } from './EditLockAvailabilityDialog';
import LockAvailabilityButton from './LockAvailabilityButton';
import { LockAvailabilityContent } from './LockAvailabilityContent';
import { LockAvailabilityOverrideDialog } from './LockAvailabilityOverrideDialog';


export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export interface LockAvailabilityDay {
    day: Day;
    available: boolean;
    startTime: TimeString | null | undefined;
    endTime: TimeString | null | undefined;
}

export interface LockAvailabilityContextValue {
    lockAvailability?: LockAvailability;
    setLockAvailability: Dispatch<SetStateAction<LockAvailability | undefined>>;
    lockAvailabilityOverrides: LockAvailabilityOverride[];
    setLockAvailabilityOverrides: Dispatch<SetStateAction<LockAvailabilityOverride[]>>;
    lockSuspension?: LockSuspension;
    setLockSuspension: Dispatch<SetStateAction<LockSuspension | undefined>>;
    overriddenCustomerNames?: string[];
    setOverriddenCustomerNames: Dispatch<SetStateAction<string[] | undefined>>;
}

export const LockAvailabilityContext = createContext<LockAvailabilityContextValue>({
    lockAvailability: undefined,
    setLockAvailability: () => { },
    lockAvailabilityOverrides: [],
    setLockAvailabilityOverrides: () => { },
    lockSuspension: undefined,
    setLockSuspension: () => { },
    overriddenCustomerNames: [],
    setOverriddenCustomerNames: () => { }
});

export function useLockAvailabilityContextValue(isGlobal: boolean): [ LockAvailabilityContextValue, boolean ] {
    const [ lockAvailability, setLockAvailability ] = useState<LockAvailability>();
    const [ lockAvailabilityOverrides, setLockAvailabilityOverrides ] = useState<LockAvailabilityOverride[]>([]);
    const [ lockSuspension, setLockSuspension ] = useState<LockSuspension>();
    const [ overriddenCustomerNames, setOverriddenCustomerNames ] = useState<string[] | undefined>();
    const [ loading, setLoading ] = useState(true);

    const { customerID } = useParams();
    const { id: clientID } = useGetCurrentAccount();
    const pageMessage = usePageMessage();
    const { customers } = useContext(ClientContext);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const [
                [ availability ], overrides, lockSuspension, activeCustomerIds
            ] = await Promise.all([
                api.availability.getLockAvailabilities(clientID, customerID),
                api.availability.getLockAvailabilityOverrides(clientID, customerID),
                api.availability.getCurrentLockSuspension(clientID, customerID),
                isGlobal ? api.availability.getActiveCustomerIds(clientID) : []
            ] as const);

            setLockAvailability(availability);
            setLockAvailabilityOverrides(overrides);
            setLockSuspension(lockSuspension);

            if (isGlobal) {
                setOverriddenCustomerNames(activeCustomerIds.map((
                    activeCustomerId
                ) => customers?.find(customer => customer.id === activeCustomerId)?.name
                    || 'Unknown Customer'));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching lock availability', error);
        }

        setLoading(false);
    }, [
        clientID, customerID, isGlobal, pageMessage, customers, setLoading
    ]));

    const lockAvailabilityContextValue = useMemo(() => ({
        lockAvailability,
        setLockAvailability,
        lockAvailabilityOverrides,
        setLockAvailabilityOverrides,
        lockSuspension,
        setLockSuspension,
        overriddenCustomerNames,
        setOverriddenCustomerNames
    }), [
        lockAvailability, setLockAvailability, lockAvailabilityOverrides,
        setLockAvailabilityOverrides, lockSuspension, setLockSuspension,
        overriddenCustomerNames, setOverriddenCustomerNames
    ]);

    return [ lockAvailabilityContextValue, loading ];
}

export default function LockAvailabilityPage() {
    const isGlobal = true;

    const [ lockAvailabilityContextValue, loading ] = useLockAvailabilityContextValue(isGlobal);

    return (
        <LockAvailabilityContext.Provider value={lockAvailabilityContextValue}>
            <Page
                header="Lock availability"
                headerActions={(
                    <LockAvailabilityButton />
                )}
                loading={loading}
            >
                <LockAvailabilityContent />

                <RoutedDialogManager routes={routes} />
            </Page>
        </LockAvailabilityContext.Provider>
    );
}

const routes = {
    ':day/edit': EditLockAvailabilityDialog,
    ':id/edit-override': LockAvailabilityOverrideDialog,
    add: LockAvailabilityOverrideDialog
};
