export function getUnderwritingCategories(clientId: string, loanID: string): Promise<UnderwritingCategory[]> {
    // return apiUtils.get(`/client/${clientId}/underwriting-category`);

    const localStorageValue = JSON.parse(localStorage.getItem(loanID) || '[]');
    console.log(localStorageValue);

    // TODO temporarily using mocks until the backend is implemented
    return new Promise((resolve) => {
        resolve(localStorageValue.length ? localStorageValue : underwritingCategories);
    });
}

export function getUnderwritingStepDetail(
    clientId: string, underwritingCategoryId: string, loanID: string, underwritingStepId: string
): Promise<UnderwritingStepDetail> {
    // eslint-disable-next-line max-len
    // return apiUtils.get(`/client/${clientId}/underwriting-category/${underwritingCategoryId}/underwriting-step/${underwritingStepId}`);

    // TODO temporarily using mocks until the backend is implemented
    return new Promise((resolve) => {
        resolve(getUnderwritingStepById(underwritingStepId, loanID)!);
    });
}

export function updateUnderwritingStep(
    clientId: string, underwritingCategoryId: string, loanID: string, underwritingStep: UnderwritingStepDetail
): Promise<UnderwritingStepDetail> {
    // eslint-disable-next-line max-len
    // return apiUtils.put(`/client/${clientId}/underwriting-category/${underwritingCategoryId}/underwriting-step/${underwritingStep.id}`, underwritingStep);
    replaceUnderwritingStepById(underwritingStep, loanID);

    // TODO temporarily using mocks until the backend is implemented
    return new Promise((resolve) => {
        resolve(underwritingStep);
    });
}

export interface UnderwritingCategory {
    id: string;
    name: string;
    steps: UnderwritingStep[];
}

export interface UnderwritingStep {
    id: string;
    name: string;
    isSubmitted?: boolean; // TODO this was added on the frontend
}

export interface UnderwritingStepDetail extends UnderwritingStep{
    questions: UnderwritingQuestion[];
}

export enum UnderwritingQuestionType {
    SINGLE_SELECT = 'SINGLE_SELECT',
    FORM = 'FORM',
    FOREACH = 'FOREACH',
    INFO = 'INFO',
    TABLE_ENTRY = 'TABLE_ENTRY',
    GROUP = 'GROUP',
    CUSTOM = 'CUSTOM'
}

export enum CustomQuestionType {
    AUS_DETAILS = 'AUS_DETAILS',
    LIABILITIES = 'LIABILITIES',
    ASSET_ACCOUNTS = 'ASSET_ACCOUNTS',
}

export interface UnderwritingQuestion<T = any> {
    id: string;
    type: UnderwritingQuestionType;
    customQuesionType?: CustomQuestionType; // for type CUSTOM
    next?: UnderwritingAction; // for type FORM, INFO, TABLE_ENTRY
    text?: string; // the label of the question

    // eslint-disable-next-line max-len
    showNextQuestion?: boolean; // for any question type that has a next property (Not SINGLE_SELECT). Most question require the user the answer the question and hit next before the next question is shown. This property is used to automatically show the next question even if the current question is unanswered, however all questions must still be answered before the step is able to be submitted.

    // TODO variant DROPDOWN was added on the frontend
    variant?: 'SIMPLE' | 'RADIOBUTTON' | 'DROPDOWN'; // if type is SINGLE_SELECT
    choices?: UnderwritingAction[]; // for type SINGLE_SELECT

    // eslint-disable-next-line max-len
    data?: Record<string, any>; // for any question type. This property is only used to pass supplemental data to the frontend to populate handbar templates in the question text

    fields?: UnderwritingField[]; // if type is FORM

    addButtonText?: string; // if type is FOREACH

    // TODO (idea for improvement) rename this to data and use it for all the question types
    // the 'data' can be used for value injection in the text for all questions
    arrayData?: T[]; // if type is FOREACH
    arrayOptions?: ForeachArrayOptions; // if type is FOREACH
    questions?: UnderwritingQuestion[]; // if type is FOREACH, or GROUP

    entityName?: string; // if type is TABLE_ENTRY
    columns?: { // if type is TABLE_ENTRY
        columnName: string;
        loanFieldId: string;
    }[];
    values?: { // if type is TABLE_ENTRY
        [loanFieldId: string]: string;
    }[]; // this represents the 'answer' of the table

    icon?: UnderwritingIconType; // if type is INFO

    // TODO (idea for improvement) use answer as an array for foreach questions instead of arrayData.i.answers
    answer?: T; // the answer is for sending the data to the api
}

export enum UnderwritingIconType {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export interface UnderwritingAction {
    action: 'CONTINUE' | 'END';
    continueTo?: string;
    text: string;
    confirmationMessage?: string;
    // TODO we should remove sideEffects from this interface and add them to websocket
    sideEffects?: UnderwritingSideEffect[];
}

export interface UnderwritingSideEffect {
    type: 'SUSPEND_LOAN' | 'GENERATE_CONDITION';
    subType?: 'FOREACH'; // generate a sideEffect for each row of a table question
    text?: string;
    description?: string;
}

export enum UnderwritingFieldType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    CURRENCY = 'CURRENCY',
    PERCENTAGE = 'PERCENTAGE'
    // TODO (idea for improvement) - add select & multiselect. We can pass the options in the field object
    // SELECT = 'SELECT',
    // MULTI_SELECT = 'MULTI_SELECT',
}

export interface UnderwritingField {
    dataSourceId: string;
    type: UnderwritingFieldType;
    label: string;
    size: number; // size is the span of the field out of 12 horizontal columns
    isDisabled: boolean;
    helperText?: string;
    value?: string | Date | null; // this represents the 'answer' of the field
    isRequired?: boolean; // it is assumed all fields are required unless specified false
}

export interface ForeachArrayOptions {
    dataSourceId: string;
    iterationName: string;
    indexName: string;
}

// TODO all of the below mock data should be REMOVED when the backend is implemented
// This is all duplicated from the underwriting mock data file, but it needs to be
// defined here and not imported from the mock file due to import order issues crashing the app
function getUnderwritingStepById(underwritingStepId: string, loanID: string): UnderwritingStepDetail | undefined {
    const localStorageValue = JSON.parse(localStorage.getItem(loanID) || '[]');

    return (
        localStorageValue.length ? localStorageValue as UnderwritingStepDetail[] : underwritingSteps
    ).find(step => step.id === underwritingStepId);
}

function replaceUnderwritingStepById(underwritingStep: UnderwritingStepDetail, loanID: string): void {
    const localStorageValue = JSON.parse(localStorage.getItem(loanID) || '[]');
    const uwSteps = localStorageValue.length ? localStorageValue as UnderwritingStepDetail[] : underwritingSteps;

    const index = uwSteps.findIndex(step => step.id === underwritingStep.id);
    if (index !== -1) {
        uwSteps[index] = underwritingStep;
    }

    localStorage.setItem(loanID, JSON.stringify(uwSteps));
}

const {
    TEXT, NUMBER, DATE, CURRENCY, PERCENTAGE
} = UnderwritingFieldType;

let underwritingCategoryId = 0;
let underwritingStepId = 0;


const loanSellerData = [
    {
        name: 'Seller 1'
    },
    {
        name: 'Seller 2'
    }
];

const loanBuyerData = [
    {
        name: 'Buyer 1'
    },
    {
        name: 'Buyer 2'
    }
];

// TODO remove sideEffects from the mock data when they are implemented in websocket
const underwritingSteps: UnderwritingStepDetail[] = [
    {
        id: `${underwritingStepId++}`,
        name: 'AUS Review',
        questions: [
            {
                id: '-1',
                type: UnderwritingQuestionType.CUSTOM,
                customQuesionType: CustomQuestionType.AUS_DETAILS,
                next: {
                    text: 'Continue',
                    action: 'CONTINUE',
                    continueTo: '0'
                }
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the above information match the AUS results',
                choices: [
                    {
                        action: 'END',
                        text: 'No',
                        sideEffects: [
                            {
                                type: 'GENERATE_CONDITION',
                                text: "AUS results don't match loan information"
                            }
                        ]
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Have conditions been added to address all AUS findings?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Sales Contract',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the sales contract on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the property on the sales contract match the following address from the file?\n<center>{{ address }}</center>',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                data: {
                    address: '120 Lake Dr<br>West Greenwich, RI 02817'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.FORM,
                text: 'Enter the following information from the sales contract:',
                fields: [
                    {
                        dataSourceId: 'loan.data.purchase_price',
                        label: 'Purchase price',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.seller_concessions',
                        label: 'Seller concessions',
                        size: 6,
                        helperText: 'Maximum concessions: $4,000',
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money',
                        label: 'Earnest money deposit',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.contract_execution_date',
                        label: 'Contract execution date',
                        size: 6,
                        isDisabled: false,
                        type: DATE
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Seller Details',
        questions: [
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.seller_name',
                        label: 'Selling agent',
                        size: 6,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.seller_address',
                        label: 'Listing agent',
                        size: 6,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '1',
                    text: 'Next'
                },
                id: '0'
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FOREACH,
                text: '<span style="font-size: small; color: gray;">Property seller</span><br>{{ seller.name }}',
                addButtonText: 'Add Seller',
                arrayOptions: {
                    dataSourceId: 'loan.data.sellers',
                    iterationName: 'seller',
                    indexName: 'i'
                },
                arrayData: [ { name: 'George Sellerson' } ],
                fields: [
                    {
                        dataSourceId: 'name',
                        label: 'Seller name',
                        size: 12,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Continue'
                }
            },
            {
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '2.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the seller a company?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the seller a government agency or home builder?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.2',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Does the file contain proof of the individual corporate owners?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.3',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is this an arms length transaction?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.4',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Does contract show any gifts from seller to buyer?',
                        choices: [
                            {
                                action: 'END',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Report details',
        questions: [
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.borrower_name',
                        label: 'Borrower name',
                        size: 4,
                        isDisabled: true,
                        type: TEXT,
                        value: 'William J Clinton'
                    },
                    {
                        dataSourceId: 'loan.data.report_date',
                        label: 'Report date',
                        size: 4,
                        isDisabled: true,
                        type: DATE,
                        value: '06/22/2024'
                    },
                    {
                        dataSourceId: 'loan.data.report_number',
                        label: 'Report #',
                        size: 4,
                        isDisabled: true,
                        type: TEXT,
                        value: '722254132677186'
                    },
                    {
                        dataSourceId: 'loan.data.transunion',
                        label: 'Transunion',
                        size: 4,
                        isDisabled: true,
                        type: NUMBER,
                        value: '720'
                    },
                    {
                        dataSourceId: 'loan.data.equifax',
                        label: 'Equifax',
                        size: 4,
                        isDisabled: false,
                        type: NUMBER
                    },
                    {
                        dataSourceId: 'loan.data.experian',
                        label: 'Experian',
                        size: 4,
                        isDisabled: true,
                        type: NUMBER,
                        value: '678'
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '1',
                    text: 'Next'
                },
                id: '0'
            },
            {
                type: UnderwritingQuestionType.INFO,
                id: '1',
                text: 'Verify credit report data and enter missing information',
                next: {
                    action: 'END',
                    text: 'Next'
                }
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Credit inquiries',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the credit report show other inquiries in the last 90 days?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a letter of explanation for each of those inquiries?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.TABLE_ENTRY,
                text: 'Inquiries requiring explanation',
                entityName: 'inquiry',
                columns: [
                    {
                        columnName: 'Creditor name',
                        loanFieldId: 'creditor_name'
                    },
                    {
                        columnName: 'Date',
                        loanFieldId: 'date'
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done',
                    sideEffects: [
                        {
                            type: 'GENERATE_CONDITION',
                            subType: 'FOREACH',
                            text: 'Provide letter of explanation for the following credit inquiry',
                            description: '{{ creditor_name }} on {{ date }}'
                        }
                    ]
                },
                id: '2'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Mortgage history',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there satisfactory 12 month mortgage payment history for all properties owned?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.TABLE_ENTRY,
                text: 'Properties requiring mortgage history',
                entityName: 'property',
                columns: [
                    {
                        columnName: 'Property address',
                        loanFieldId: 'loan.data.properties[].address'
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does any mortgage have late payments in the last 12 months?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.FORM,
                text: 'How many late payments are there in each category?',
                fields: [
                    {
                        dataSourceId: 'loan.data.late_payments.30_days',
                        label: '30 days late',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.60_days',
                        label: '60 days late',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.90_days',
                        label: '90 days late',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.120_days',
                        label: '120 days late',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done',
                    sideEffects: [
                        {
                            type: 'GENERATE_CONDITION',
                            text: 'Late payments in the last 12 months'
                        }
                    ]
                },
                id: '3'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Gift Funds',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are there any gift funds for this transaction?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                answer: 'Yes'
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FOREACH,
                text: '**{{ gift_fund.amount }}** from **{{ gift_fund.name }}**',
                addButtonText: 'Add Gift',
                arrayOptions: {
                    dataSourceId: 'loan.data.gift_funds',
                    iterationName: 'gift_fund',
                    indexName: 'i'
                },
                fields: [
                    {
                        dataSourceId: 'name',
                        label: 'Donor name',
                        size: 12,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'amount',
                        label: 'Amount',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                /**
                 * Array data coming through in this format is an assumption based on the UI.
                 * We can rework this depending on what makes sense for the backend.
                 */
                arrayData: [
                    {
                        name: 'Some Giver',
                        amount: '$5000',
                        answers: [
                            {
                                id: '0',
                                value: 'Yes'
                            },
                            {
                                id: '1',
                                value: 'Borrower'
                            },
                            {
                                id: '2',
                                value: 'No'
                            }
                        ]
                    },
                    {
                        name: 'Better Giver',
                        amount: '$15000',
                        answers: [
                            {
                                id: '0',
                                value: 'Yes'
                            },
                            {
                                id: '1',
                                value: 'Borrower'
                            },
                            {
                                id: '2',
                                value: 'No'
                            }
                        ]
                    }
                ],
                questions: [
                    {
                        id: '1.i.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the gift letter on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.1',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '1.i.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Who is the gift fund recipient?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.2',
                                text: 'Borrower'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.2',
                                text: 'Closing agent'
                            },
                            {
                                action: 'END',
                                text: 'Gift of equity'
                            }
                        ]
                    },
                    {
                        id: '1.i.2',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are the donor funds sourced?',
                        choices: [
                            {
                                action: 'END',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    }
                ],
                /**
                 * NOTE FOR KEN:
                 * We need to have one next action on the foreach that represents what happens after all items in the
                 * foreach have been answered. It doesn't make sense to have 1.1.1 trigger 2, because 1.2.1 might not
                 * be answered yet. We can handle this but using END as the action on the subquestions to represent they
                 * are ready. Once all subquestions in the foreach reach end state, we can trigger the next action on
                 * the parent foreach question
                 */
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Continue'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'All items complete',
                next: {
                    action: 'END',
                    text: 'Done'
                }
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Earnest Money',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is earnest money deposit required in the sales contract?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FORM,
                text: 'Earnest Money',
                fields: [
                    {
                        dataSourceId: 'loan.data.earnest_money',
                        label: 'Earnest money deposit',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money_1',
                        label: 'Earnest money total amount',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money_2',
                        label: 'Earnest money interest',
                        size: 12,
                        isDisabled: false,
                        type: PERCENTAGE
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the deposit cleared the borrower\'s bank account?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Net Equity',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the borrower gaining net equity from the sale of real estate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.net_equity',
                        label: 'Net equity gained',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the settlement statement present?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    }, {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'No'
                    }
                ]
            },
            {
                id: '3',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are the funds present in one of the borrower\'s accounts?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    }, {
                        action: 'END',
                        text: 'No'
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Asset Accounts',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.CUSTOM,
                customQuesionType: CustomQuestionType.ASSET_ACCOUNTS,
                next: {
                    text: 'Submit',
                    action: 'END'
                }
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Appraisal Waiver',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is an appraisal waived for this file?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                        // expected side effect: mark the remaining appraisal items as "waived"
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is an appraisal present on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No',
                        confirmationMessage: 'Would you like to suspend the file?',
                        sideEffects: [
                            {
                                type: 'SUSPEND_LOAN'
                            }
                        ]
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Appraisal Property',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What type of appraisal has been performed?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '1004'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '2055'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '1073'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '1075'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '1025'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '1025 Exterior'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: '2090'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '1.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Does the appraisal property address match the following address from AUS?<br><center>{{ property.address }}<center/>',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1.1',
                                text: 'No'
                            }
                        ],
                        data: {
                            property: {
                                address: '120 Lake Dr<br>West Greenwich, RI 02817'
                            }
                        }
                    },
                    {
                        id: '1.1',
                        type: UnderwritingQuestionType.FORM,
                        text: 'Address',
                        fields: [
                            {
                                dataSourceId: 'loan.data.appraisal_address.street',
                                label: 'Street',
                                size: 12,
                                isDisabled: false,
                                type: TEXT
                            },
                            {
                                dataSourceId: 'loan.data.appraisal_address.street_line_2',
                                label: 'Street line 2 (optional)',
                                size: 12,
                                isDisabled: false,
                                type: TEXT,
                                isRequired: false
                            },
                            {
                                dataSourceId: 'loan.data.appraisal_address.city',
                                label: 'City',
                                size: 6,
                                isDisabled: false,
                                type: TEXT
                            },
                            {
                                dataSourceId: 'loan.data.appraisal_address.state',
                                label: 'State',
                                size: 3,
                                isDisabled: false,
                                type: TEXT
                            },
                            {
                                dataSourceId: 'loan.data.appraisal_address.zip',
                                label: 'Zip',
                                size: 3,
                                isDisabled: false,
                                type: NUMBER
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '2',
                            text: 'Next'
                        }
                    }
                ]
            },
            {
                id: '2',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '2.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'What type of property is the appraisal for?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Single family'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Condo'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'PUD'
                            },
                            {
                                action: 'END',
                                text: 'Co-op'
                            },
                            {
                                action: 'END',
                                text: 'Manufactured home'
                            }
                        ]
                    },
                    {
                        id: '2.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the property attached or detached?',
                        choices: [
                            {
                                action: 'END',
                                text: 'Attached'
                            },
                            {
                                action: 'END',
                                text: 'Detached'
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Appraisal Details',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '0.0',
                        type: UnderwritingQuestionType.FORM,
                        showNextQuestion: true,
                        fields: [
                            {
                                dataSourceId: 'loan.data.property_taxes.amount',
                                label: 'Annual taxes',
                                size: 4,
                                isDisabled: false,
                                type: CURRENCY
                            },
                            {
                                dataSourceId: 'loan.data.special_assessments.amount',
                                label: 'Special assessments',
                                size: 4,
                                isDisabled: false,
                                type: UnderwritingFieldType.TEXT
                            },
                            {
                                dataSourceId: 'loan.data.hoa_fees.amount',
                                label: 'HOA fees',
                                size: 4,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '0.1',
                            text: 'Next'
                        }
                    },
                    {
                        id: '0.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'RADIOBUTTON',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1',
                                text: 'Per month'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1',
                                text: 'Per year'
                            }
                        ]
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the market declining?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    }
                ]
            },
            {
                id: '2',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What is the condition rating of the property?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C1'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C2'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C3'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C4'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C5'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'C6'
                    }
                ]
            },
            {
                id: '3',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Appraisal was made',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'As is'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'Subject to'
                    }
                ]
            },
            {
                id: '4',
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.appraisal_date',
                        helperText: 'Expires 04/04/2025',
                        label: 'Appraisal date',
                        size: 6,
                        isDisabled: false,
                        type: DATE
                    },
                    {
                        dataSourceId: 'loan.data.appraisal_value',
                        label: 'Appraisal value',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                }
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Appraisal Review',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a 1040D for the "subject to" appraisal?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the report contain interior photos?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the property rural or over 10 acres?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'No'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: '<strong>Is the appraised value of $415,000 supported?<strong/>',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '3'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Appraiser Details',
        questions: [
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: '0',
                        label: 'Appraiser name',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '1',
                        label: 'License number',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '2',
                        label: 'Appraisal company',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '0'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'SSR/UCDP',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '0.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the Fannie Mae UCDP in file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '0.1',
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.fannie_ucdp_score',
                                label: 'Fannie UCDP Score',
                                size: 12,
                                isDisabled: false,
                                type: NUMBER
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '1',
                            text: 'Next'
                        }
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '1.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the Freddie Mac UCDP in file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.1',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '1.1',
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.freddie_ucdp_score',
                                label: 'Freddie UCDP Score',
                                size: 12,
                                isDisabled: false,
                                type: NUMBER
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        }
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Condo Info',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the condo project new?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the entire project contain less than 5 total units?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.ERROR,
                text: 'Full condo review required',
                next: {
                    action: 'CONTINUE',
                    continueTo: '5',
                    text: 'Next'
                },
                id: '2'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.SUCCESS,
                text: 'No condo review required',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.WARNING,
                text: 'Limited condo review required',
                next: {
                    action: 'CONTINUE',
                    continueTo: '6',
                    text: 'Next'
                },
                id: '4'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are the following warranty documents included?\n- Full condo questionnaire\n- HOA Budget\n- HOA Balance sheet',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '5'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are the following warranty documents included?\n- Limited condo questionnaire',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '7',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '6'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the limited review sufficient?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '7'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Fraud check',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a fraud check?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a processor certification to satisfy the alert(s)?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Derogatories',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '0.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any bankruptcies, foreclosures, or short sales?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '0.2',
                        type: UnderwritingQuestionType.TABLE_ENTRY,
                        columns: [
                            {
                                columnName: 'Borrower',
                                loanFieldId: 'loan.data.derogatories[].borrower'
                            },
                            {
                                columnName: 'Type',
                                loanFieldId: 'loan.data.derogatories[].type'
                            },
                            {
                                columnName: 'Disposition',
                                loanFieldId: 'loan.data.derogatories[].disposition'
                            },
                            {
                                columnName: 'Date',
                                loanFieldId: 'loan.data.derogatories[].date'
                            },
                            {
                                columnName: 'Extenuating',
                                loanFieldId: 'loan.data.derogatories[].extenuating'
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '1',
                            text: 'Next'
                        }
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '1.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any outstanding judgements or liens?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '1.2',
                        type: UnderwritingQuestionType.TABLE_ENTRY,
                        columns: [
                            {
                                columnName: 'Borrower',
                                loanFieldId: 'loan.data.derogatories[].borrower'
                            },
                            {
                                columnName: 'Plaintiff',
                                loanFieldId: 'loan.data.derogatories[].plaintiff'
                            },
                            {
                                columnName: 'Item type',
                                loanFieldId: 'loan.data.derogatories[].item_type'
                            },
                            {
                                columnName: 'Amount',
                                loanFieldId: 'loan.data.derogatories[].amount'
                            },
                            {
                                columnName: 'Date',
                                loanFieldId: 'loan.data.derogatories[].date'
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '2',
                            text: 'Next'
                        }
                    }
                ]
            },
            {
                id: '2',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '2.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any open collections or charge offs?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.2',
                        type: UnderwritingQuestionType.TABLE_ENTRY,
                        columns: [
                            {
                                columnName: 'Borrower',
                                loanFieldId: 'loan.data.derogatories[].borrower'
                            },
                            {
                                columnName: 'Company',
                                loanFieldId: 'loan.data.derogatories[].company'
                            },
                            {
                                columnName: 'Account #',
                                loanFieldId: 'loan.data.derogatories[].account_number'
                            },
                            {
                                columnName: 'Balance',
                                loanFieldId: 'loan.data.derogatories[].balance'
                            },
                            {
                                columnName: 'Paying off?',
                                loanFieldId: 'loan.data.derogatories[].paying_off'
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        }
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'AKAs & Addresses',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '0.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any unexplained AKAs on the credit report?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            }
                        ]
                    },
                    {
                        id: '0.2',
                        type: UnderwritingQuestionType.TABLE_ENTRY,
                        text: 'AKAs requiring explanation',
                        columns: [
                            {
                                columnName: 'AKA',
                                loanFieldId: 'loan.data.aka'
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '1',
                            text: 'Next'
                        }
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '1.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any unexplained address variations on the credit report?',
                        choices: [
                            {
                                action: 'END',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1.2',
                                text: 'Yes'
                            }
                        ]
                    },
                    {
                        id: '1.2',
                        type: UnderwritingQuestionType.TABLE_ENTRY,
                        text: 'Addresses requiring explanation',
                        columns: [
                            {
                                columnName: 'Address',
                                loanFieldId: 'loan.data.address'
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        }
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Other liabilities',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the borrower required to pay child support or alimony?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a divorce decree on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Not required'
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Liabilities',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.CUSTOM,
                customQuesionType: CustomQuestionType.LIABILITIES,
                next: {
                    text: 'Submit',
                    action: 'END'
                }
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Living Trust Review',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'RADIOBUTTON',
                text: 'Which of the following best describes the titling of this loan regarding a living trust?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Title is currently vested in the trust and will **remain** in the trust'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Title is currently vested in the trust, but **will be deeded out** of the trust at closing'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Title is **not** currently in a trust but **will be vested** in a trust at closing'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.ERROR,
                text: 'Full living trust review required.',
                next: {
                    action: 'CONTINUE',
                    text: 'Next',
                    continueTo: '3'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.SUCCESS,
                text: 'No living trust review required.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the full living trust document on file?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'Yes'
                    }
                ],
                id: '3'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the trust approval been obtained from the title company?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '4'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Tax Certification',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '0.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the tax certification on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '0.1',
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.annual_property_taxes',
                                label: 'Annual Property Taxes',
                                size: 12,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        }
                    }
                ]
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Mortgage Insurance',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is mortgage insurance required?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What is the loan purpose listed on the mortgage insurance certificate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Purchase'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Refinance'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Cash Out Refinance'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What is the property type listed on the mortgage insurance certificate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Single Family'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Condo'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'PUD'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Co-op'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Manufactured Home'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.FORM,
                text: 'Enter the following information from the mortgage insurance certificate:',
                fields: [
                    {
                        dataSourceId: '1',
                        label: 'Loan Amount',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: '2',
                        label: 'LTV',
                        size: 3,
                        isDisabled: false,
                        type: PERCENTAGE
                    },
                    {
                        dataSourceId: '3',
                        label: 'CLTV',
                        size: 3,
                        isDisabled: false,
                        type: PERCENTAGE
                    },
                    {
                        dataSourceId: '4',
                        label: 'Monthly Premium',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: '5',
                        label: 'Coverage Percentage',
                        size: 6,
                        isDisabled: false,
                        type: PERCENTAGE
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Title Commitment',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the title on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.FORM,
                // eslint-disable-next-line camelcase
                data: { loan: { data: { commitment_expiry_date: '04/04/2022' } } },
                fields: [
                    {
                        dataSourceId: '1',
                        label: 'Commitment Date',
                        size: 6,
                        helperText: 'Expires {{ loan.data.commitment_expiry_date }}',
                        isDisabled: false,
                        type: DATE
                    },
                    {
                        dataSourceId: '2',
                        label: 'Loan Amount',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: "Is the lender's name correct?",
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Yes'
                            }
                        ],
                        id: '2.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the vesting correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'Yes'
                            }
                        ],
                        id: '2.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title clear of defects?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'Yes'
                            }
                        ],
                        id: '2.2'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is COVID-19 hold harmless language included?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'Yes'
                            }
                        ],
                        id: '2.3'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any delinquent taxes?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.5',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.5',
                                text: 'Yes'
                            }
                        ],
                        id: '2.4'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is a 24 month chain of title shown?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.6',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.6',
                                text: 'Yes'
                            }
                        ],
                        id: '2.5'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title fee simple or leasehold?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.7',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.7',
                                text: 'Yes'
                            }
                        ],
                        id: '2.6'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is an insured closing letter on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.8',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.8',
                                text: 'Yes'
                            }
                        ],
                        id: '2.7'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is at least one borrower currently vested on the title?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.9',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.9',
                                text: 'Yes'
                            }
                        ],
                        id: '2.8'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Has any borrower been on the title for at least 6 months?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.10',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.10',
                                text: 'Yes'
                            }
                        ],
                        id: '2.9'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title currently vested in a trust or will it be at closing?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'Yes'
                            }
                        ],
                        id: '2.10'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Title commitment questions are complete.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Fraud Report',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the fraud report on file?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Have all high and medium alerts been addressed with conditions or notes?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the fraud report been checked for any undisclosed REO or other addresses?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '2'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Parties Review',
        questions: [
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Have the following parties been validated on the fraud report?',
                next: {
                    action: 'CONTINUE',
                    continueTo: '1',
                    text: 'Continue'
                },
                id: '0'
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Appraiser</strong>',
                questions: [
                    {
                        id: '1.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        data: {
                            loan: {
                                appraiser: {
                                    name: 'John Doe',
                                    licenseNumber: '123456'
                                }
                            }
                        },
                        text: '{{ loan.appraiser.name }}<br><small><span style="color: #888;">License #: {{ loan.appraiser.licenseNumber }}</span></small>',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '2',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Appraisal company</strong>',
                questions: [
                    {
                        id: '2.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.appraisalCompany.name }}',
                        data: { loan: { appraisalCompany: { name: 'Appraisal Co.' } } },
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '3',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Loan officer</strong>',
                questions: [
                    {
                        id: '3.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.loanOfficer.name }}<br><small><span style="color: #888;">NMLS ID: {{ loan.loanOfficer.nmlsId }}</span></small>',
                        data: {
                            loan: {
                                loanOfficer: {
                                    name: 'Jane Doe',
                                    nmlsId: '654321'
                                }
                            }
                        },
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '4',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Originating company</strong>',
                questions: [
                    {
                        id: '4.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.originatingCompany.name }}<br><small><span style="color: #888;">NMLS ID: {{ loan.originatingCompany.nmlsId }}</span></small>',
                        data: {
                            loan: {
                                originatingCompany: {
                                    name: 'Originating Co.',
                                    nmlsId: '987654'
                                }
                            }
                        },
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '5',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Realtors</strong>',
                questions: [
                    {
                        id: '5.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.buyersAgent.name }} (buyer\'s agent)',
                        data: {
                            loan: {
                                buyersAgent: {
                                    name: 'Buyer\'s Agent',
                                    licenseNumber: '987654'
                                }
                            }
                        },
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '5.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.sellersAgent.name }} (seller\'s agent)',
                        data: {
                            loan: {
                                sellersAgent: {
                                    name: 'Seller\'s Agent',
                                    licenseNumber: '123456'
                                }
                            }
                        },
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '6',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '6',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '6',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Sellers</strong>',
                questions: loanSellerData.map((seller, index, array) => ({
                    id: `6.${index}`,
                    type: UnderwritingQuestionType.SINGLE_SELECT,
                    variant: 'SIMPLE',
                    text: `{{ loan.sellers.${index}.name }}`,
                    data: { loan: { sellers: loanSellerData } },
                    choices: [
                        {
                            action: 'CONTINUE',
                            text: 'Yes',
                            continueTo: index === array.length - 1 ? '7' : `6.${index + 1}`
                        },
                        {
                            action: 'CONTINUE',
                            text: 'No',
                            continueTo: index === array.length - 1 ? '7' : `6.${index + 1}`
                        }
                    ]
                }))
            },
            {
                id: '7',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Buyers</strong>',
                questions: loanBuyerData.map((buyer, index, array) => ({
                    id: `7.${index}`,
                    type: UnderwritingQuestionType.SINGLE_SELECT,
                    variant: 'SIMPLE',
                    text: `{{ loan.buyers.${index}.name }}`,
                    data: { loan: { buyers: loanBuyerData } },
                    choices: [
                        {
                            action: 'CONTINUE',
                            text: 'Yes',
                            continueTo: index === array.length - 1 ? '8' : `7.${index + 1}`
                        },
                        {
                            action: 'CONTINUE',
                            text: 'No',
                            continueTo: index === array.length - 1 ? '8' : `7.${index + 1}`
                        }
                    ]
                }))
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'End of parties review',
                next: {
                    action: 'END',
                    text: 'End'
                },
                id: '8'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Flood Insurance',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the flood certificate on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the flood certificate valid for the life of the loan?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'DROPDOWN',
                        text: 'Which flood zone is listed on the certificate?\n',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AE'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A1-30'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AH'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AO'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AR'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A99'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'V'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'VE'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'V1-30'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'X'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'B'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'C'
                            }
                        ],
                        id: '0.2'
                    }
                ]
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Flood insurance is required.',
                next: {
                    action: 'CONTINUE',
                    continueTo: '5',
                    text: 'Continue'
                },
                id: '3'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Flood insurance is not required.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '4'
            },
            {
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is flood insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'No'
                            }
                        ],
                        id: '5.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.2',
                                text: 'No'
                            }
                        ],
                        id: '5.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.3',
                                text: 'No'
                            }
                        ],
                        id: '5.2'
                    },
                    {
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.flood_insurance_annual_premium',
                                label: 'What is the annual premium?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            },
                            {
                                dataSourceId: 'loan.data.flood_insurance_coverage_amount',
                                label: 'What is the coverage amount?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        },
                        id: '5.3'
                    }
                ],
                id: '5'
            }
        ]
    },
    {
        id: `${underwritingStepId++}`,
        name: 'Hazard Insurance',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is hazard insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'No'
                            }
                        ],
                        id: '0.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.3',
                                text: 'No'
                            }
                        ],
                        id: '0.2'
                    },
                    {
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.hazard_insurance_annual_premium',
                                label: 'What is the annual premium?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            },
                            {
                                dataSourceId: 'loan.data.hazard_insurance_coverage_amount',
                                label: 'What is the coverage amount?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'CONTINUE',
                            continueTo: '1',
                            text: 'Next'
                        },
                        id: '0.3'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.CUSTOM,
                text: '<h3 style="font-weight: 500; font-size: 20px;">Master Insurance</h3>', // This question is ONLY used to display the title
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '2.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is master insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '2.2',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '3',
                type: UnderwritingQuestionType.CUSTOM,
                text: '<h3 style="font-weight: 500; font-size: 20px;">HO-6 (Walls in) Insurance</h3>', // This question is ONLY used to display the title
                next: {
                    action: 'CONTINUE',
                    continueTo: '4',
                    text: 'Next'
                }
            },
            {
                id: '4',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        id: '4.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is walls in insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '4.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '4.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '4.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4.2',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '4.2',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '4.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4.3',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '4.3',
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.ho6_insurance_annual_premium',
                                label: 'What is the annual premium?',
                                size: 12,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        }
                    }
                ]
            }
        ]
    }
];

export const underwritingCategories: UnderwritingCategory[] = [
    {
        id: `${underwritingCategoryId++}`,
        name: 'AUS',
        steps: [ createStepSummary('AUS Review') ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Sales Contract',
        steps: [
            createStepSummary('Sales Contract'),
            createStepSummary('Seller Details')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Credit & Liabilities',
        steps: [
            createStepSummary('Report details'),
            createStepSummary('Credit inquiries'),
            createStepSummary('Mortgage history'),
            createStepSummary('Fraud check'),
            createStepSummary('Derogatories'),
            createStepSummary('AKAs & Addresses'),
            createStepSummary('Other liabilities'),
            createStepSummary('Liabilities')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Income & Assets',
        steps: [
            createStepSummary('Gift Funds'),
            createStepSummary('Earnest Money'),
            createStepSummary('Net Equity'),
            createStepSummary('Asset Accounts')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Appraisal',
        steps: [
            createStepSummary('Appraisal Waiver'),
            createStepSummary('Appraisal Property'),
            createStepSummary('Appraisal Details'),
            createStepSummary('Appraisal Review'),
            createStepSummary('Appraiser Details'),
            createStepSummary('SSR/UCDP'),
            createStepSummary('Condo Info')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Title & MI',
        steps: [
            createStepSummary('Title Commitment'),
            createStepSummary('Living Trust Review'),
            createStepSummary('Tax Certification'),
            createStepSummary('Mortgage Insurance')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Flood & Hazard',
        steps: [
            createStepSummary('Flood Insurance'),
            createStepSummary('Hazard Insurance')
        ]
    },
    {
        id: `${underwritingCategoryId++}`,
        name: 'Fraud Report',
        steps: [
            createStepSummary('Fraud Report'),
            createStepSummary('Parties Review')
        ]
    }
];

function createStepSummary(stepName: string) {
    return {
        id: getUnderwritingStepIdByName(stepName),
        name: stepName
    };
}

function getUnderwritingStepIdByName(underwritingStepName: string): string {
    const underwritingStepId =  underwritingSteps.find(step => step.name === underwritingStepName)?.id;

    if (!underwritingStepId) {
        throw new Error(`Underwriting step with name "${underwritingStepName}" not found in mock data`);
    }

    return underwritingStepId;
}
