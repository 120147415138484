
import { rest } from 'msw';

import { CustomerAlertConfig } from '../..';
import { getMockUrl } from '../../../mocks/getMockUrl';
import { AlertType } from '../../enums/alertTypeEnum';


let alertConfigID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer-alert-config'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerAlertConfigs)
    ))),
    rest.delete(getMockUrl('/client/:clientID/customer-alert-config/:alertConfigID'), (req, res, ctx) => (res(
        ctx.status(200)
    ))),
    rest.post(getMockUrl('/client/:clientID/customer-alert-config'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json({
            ...req.body as CustomerAlertConfig,
            id: `${alertConfigID++}`
        })
    ))),
    rest.put(getMockUrl('/client/:clientID/customer-alert-config/:alertConfigID'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(customerAlertConfigs[0])
    )))
];

export const customerAlertConfigs: CustomerAlertConfig[] = [
    {
        id: `${alertConfigID++}`,
        type: AlertType.LICENSE_EXPIRATION,
        duration: 4
    },
    {
        id: `${alertConfigID++}`,
        type: AlertType.COMPENSATION_FEE,
        duration: 9
    }
];
