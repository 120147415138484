import { LoanActivity, loanActivityTypeDisplay } from '@api';
import { Typography } from '@mui/material';
import { formatPastDate } from '@utils';

import { LoanAlertCard } from './LoanAlertCard';
import styles from './RecentLoans.module.scss';


interface RecentLoanActivityProps {
    loanActivity: LoanActivity[];
}

export function RecentLoanActivity({ loanActivity }: RecentLoanActivityProps) {
    return (
        <div>
            <Typography
                fontWeight={500}
                className={styles.sectionHeader}
            >
                Your recent loans
            </Typography>

            <div className={styles.alertSection}>
                {loanActivity.map((loanActivity) => (
                    <LoanAlertCard
                        key={loanActivity.id}
                        loanID={loanActivity.loanId}
                        loanNumber={loanActivity.loanNumber}
                        date={loanActivity.date}
                    >
                        <div className={styles.activityInfo}>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                {`${`${loanActivityTypeDisplay[loanActivity.type]} ${formatPastDate(loanActivity.date)}`}`}
                            </Typography>
                        </div>
                    </LoanAlertCard>
                ))}
            </div>
        </div>
    );
}
