import {
    CustomerDetails, CustomerPendingSteps, CustomerStatus, PermissionType, Product, UnderwritingType
} from '@api';
import { WarningAmber } from '@mui/icons-material';
import { Button, Tooltip, Typography } from '@mui/material';
import { useHasPermission } from '@utils/hooks';
import { Link } from 'react-router-dom';

import EditableSectionCard from '../../../components/EditableSectionCard';

import styles from './CustomerProductsCard.module.scss';
import { ViewProductTypeSection } from './ViewProductTypeSection';


const { DELEGATED, NON_DELEGATED } = UnderwritingType;

interface CustomerProductsCardProps {
    customer: CustomerDetails | undefined;
    products: Product[] | undefined;
}

export default function CustomerProductsCard({ customer, products }: CustomerProductsCardProps) {
    const [ canEdit ] = useHasPermission([ PermissionType.MANAGE_ELIGIBLE_PRODUCTS ]);

    const enabledProductTypes = customer?.productTypeConfigs.filter(({ isEnabled }) => isEnabled);
    const delProducts = enabledProductTypes?.filter(({ underwritingType }) => underwritingType === DELEGATED);
    const nonDelProducts = enabledProductTypes?.filter(({ underwritingType }) => underwritingType === NON_DELEGATED);

    return (
        <EditableSectionCard
            header="Eligible products"
            headerIcon={customer?.pendingSteps?.includes(CustomerPendingSteps.ELIGIBLE_PRODUCTS) && (
                <Tooltip title={customer?.status === CustomerStatus.PENDING ? 'Required for approval' : 'Needs attention'}>
                    <WarningAmber
                        fontSize="small"
                        color="warning"
                    />
                </Tooltip>
            )}
            editTo="products"
            disableEdit={!canEdit}
            disabledTooltip="You don't have permission to edit eligible products"
        >
            {!customer ? (
                <>
                    <Typography color="textSecondary">
                        Eligible products have not been configured for this customer.
                    </Typography>

                    <Button
                        className={styles.productsButton}
                        component={Link}
                        to="products"
                    >
                        Configure products
                    </Button>
                </>
            ) : (
                <div className={styles.productTypesContainer}>
                    {!!delProducts?.length && (
                        <>
                            <Typography color="textSecondary">
                                Delegated
                            </Typography>

                            {delProducts.map((config) => (
                                <ViewProductTypeSection
                                    key={config.id}
                                    productTypeConfig={config}
                                    products={products?.filter(({ id, productType }) => (
                                        customer.eligibleProductIds.includes(id) && productType === config.productType
                                    ))}
                                />
                            ))}
                        </>
                    )}

                    {!!nonDelProducts?.length && (
                        <>
                            <Typography color="textSecondary">
                                Non-delegated
                            </Typography>

                            {nonDelProducts.map((config) => (
                                <ViewProductTypeSection
                                    key={config.id}
                                    productTypeConfig={config}
                                    products={products?.filter(({ id, productType }) => (
                                        customer.eligibleProductIds.includes(id) && productType === config.productType
                                    ))}
                                />
                            ))}
                        </>
                    )}

                    {!delProducts?.length && !nonDelProducts?.length && (
                        <Typography>
                            This customer has no eligible products
                        </Typography>
                    )}
                </div>
            )}
        </EditableSectionCard>
    );
}
