import { NotificationType, PaginatedGetParams } from '@api';
import { PaginatedResponse } from '@tsp-ui/core';

import { apiUtils, getAccountBaseUrl } from '../api-utils';


export function getAllNotifications(
    clientId: string, params: GetNotificationsParams, customerId?: string
): Promise<PaginatedResponse<Notification>> {
    return apiUtils.get<GetNotificationsParams>(`${getAccountBaseUrl(clientId, customerId)}/notification`, {
        ...params,
        pageSize: 25
    });
}

export function updateNotification(
    clientId: string, notificationID: string, notificiationUpdates: Partial<Notification>, customerId?: string
): Promise<void> {
    return Promise.resolve(); // TODO post-demo re-enable
    // return apiUtils.patch(`${getAccountBaseUrl(clientId, customerId)}/notification/${notificationID}`
    // , notificiationUpdates);
}

export interface Notification {
    id: string;
    type: NotificationType;
    description: string;
    loanId: string;
    loanNumber: string;
    createdDate: string;
    isRead: boolean; // represents if user has clicked on or dismissed the notification
    isViewed: boolean; // represents if user has viewed the noticiation in dropdown
}

interface GetNotificationsParams extends PaginatedGetParams {
    isRead?: boolean;
    date?: string | null;
    type?: NotificationType;
    loanNumber?: string;
}
