import { Paper, Typography } from '@mui/material';
import { Loader } from '@tsp-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './StatusActionsCard.module.scss';


interface StatusActionsCardProps {
    actions?: ReactNode;
    checklistItems?: ReactNode;
    noBorder: boolean;
    status: string;
    variant: 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'SUSPENDED';
    children?: ReactNode;
    isLoading?: boolean;
    label?: ReactNode;
}

export default function StatusActionsCard({
    actions, checklistItems, noBorder, status, variant, children, isLoading, label
}: StatusActionsCardProps) {
    return (
        <Paper
            className={clsx(styles.root, {
                [styles.noBorder]: noBorder
            })}
            variant="outlined"
        >
            <Typography
                variant="body2"
                className={clsx(styles.chip, styles[variant.toLowerCase()])}
            >
                {status}
            </Typography>

            {checklistItems && (Array.isArray(checklistItems) ? !!checklistItems.length : true) && (
                <div className={styles.checklist}>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {label || 'Required for approval'}
                    </Typography>

                    {checklistItems}
                </div>
            )}

            {children}

            {actions && (
                <div className={styles.actions}>
                    {actions}
                </div>
            )}

            {isLoading && <Loader loading />}
        </Paper>
    );
}
