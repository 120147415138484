import { apiUtils, getAccountBaseUrl } from '../../api-utils';


export async function getLoanDocuments(clientId: string, loanId: string, customerId?: string): Promise<LoanDocument[]> {
    return await apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/document`);
}

export async function getLoanDocumentUrl(
    clientId: string, loanId: string, documentId: string, customerId?: string
): Promise<PreSignedDocumentUrl> {
    return await apiUtils.get(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/document/${documentId}`);
}

// TODO replace uploadDocument with uploadDocuments
export function uploadLoanDocument(
    clientId: string, loanId: string, formData: FormData | File, customerId?: string
): Promise<void> {
    return apiUtils.post(`${getAccountBaseUrl(clientId, customerId)}/loan/${loanId}/document`, formData, {
        useAutoContentType: true,
        emptyResponse: true
    });
}

export interface PreSignedDocumentUrl {
    preSignedUrl: string;
    expiration: string; // ISO date
}

export interface LoanDocument {
    id: string;
    loanId: string;
    name: string;
    containerName: string;
    instanceCount: number;
    url: string;
    uploadedByUserId: string;
    uploadedAt?: string;
    isReviewed?: boolean;
}
