import api, { LoanDocument, LoanWithActivity } from '@api';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { useCallback, useState } from 'react';


import { LoanCard } from './LoanCard';
import styles from './RecentLoanCard.module.scss';


interface RecentLoanCardProps {
    loan: LoanWithActivity;
}

export function RecentLoanCard({ loan }: RecentLoanCardProps) {
    const { id: clientId, customerId } = useGetCurrentAccount();
    const [ loanDocuments, setLoanDocuments ] = useState<LoanDocument[]>();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setLoanDocuments(
                (await api.loans.document.getLoanDocuments(
                    clientId, loan.id, customerId
                )).filter(({ instanceCount }) => instanceCount === 1)
            );
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan documents', error);
        }

        setDocsLoading(false);
    }, [
        clientId, customerId, loan.id, pageMessage
    ]));

    const [ docsLoading, setDocsLoading ] = useState(true);

    return (
        <LoanCard
            className={styles.root}
            isPendingLoan={false}
            loan={loan}
            docsLoading={docsLoading}
            numDocuments={loanDocuments?.length}
        />
    );
}
