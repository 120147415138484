import {
    LoanPricingResult, RegisteredLoan, RegistrationType, UserType
} from '@api';
import {
    Description, OpenInNew, Tune,
    WarningAmber
} from '@mui/icons-material';
import {
    Badge, Chip, Link as MuiLink, Paper, Skeleton, Tooltip, Typography
} from '@mui/material';
import {
    CurrencyTypography, ExpandableCard, IconButton, LabeledValue, getItemById,
    usePageMessage
} from '@tsp-ui/core';
import { getAccountBaseRoute, useGetCurrentAccount } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import clsx from 'clsx';
import {
    Dispatch,
    ReactNode, SetStateAction, useContext, useRef, useState
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import styles from './LoanCard.module.scss';
import NoteRatePopover from './NoteRatePopover';


const { CUSTOMER } = UserType;

interface LoanCardProps {
    className?: string;
    loanPricingResult?: LoanPricingResult;
    index?: number;
    loan?: RegisteredLoan;
    expandedContent?: ReactNode;
    /**
     * Additional details are displayed on the right side of the card
     */
    additionalDetails?: ReactNode;
    isPendingLoan: boolean;
    selectedNoteRate?: number | null;
    setSelectedNoteRate?: Dispatch<SetStateAction<number | null>>;
    currentNoteRates?: number[];
    isProductAndPricingPage?: boolean;
    numDocuments?: number;
    docsLoading?: boolean;
}

/**
 * This card can be used for loan pricing results, registered loans, and loan dashboard loans
 */
export function LoanCard({
    className,
    loanPricingResult,
    index,
    loan,
    expandedContent,
    additionalDetails,
    isPendingLoan,
    selectedNoteRate,
    setSelectedNoteRate,
    currentNoteRates,
    isProductAndPricingPage,
    numDocuments,
    docsLoading,
    ...transitionProps
}: LoanCardProps) {
    const pageMessage = usePageMessage();
    const nodeRef = useRef<HTMLDivElement | null>(null);
    const { id: clientId, customerId: accountCustomerId, accountUserType } = useGetCurrentAccount();
    const { customers } = useContext(ClientContext);

    const [ anchorEl, setAnchorEl ] = useState<HTMLButtonElement | null>(null);

    const originalRate = loan?.interestRate || loanPricingResult?.interestRate;
    const displayRate = selectedNoteRate !== null ? selectedNoteRate : originalRate;

    function handleRateClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleRateSelect(rate: number) {
        setSelectedNoteRate?.(rate);
        pageMessage.info('The note rate will change on the loan when you attempt to register');
    }

    const loanId = loan?.id || loanPricingResult?.loanId;

    const {
        loanNumber, loanAmount, borrowerName, customerId: loanCustomerId
    } = loan || loanPricingResult || {};
    const customerName = getItemById(customers || [], loanCustomerId || '')?.name;

    const CardComponent = expandedContent ? ExpandableCard : Paper;

    const showWarning = selectedNoteRate !== null && selectedNoteRate !== originalRate;

    return (
        <CSSTransition
            {...transitionProps} // required when CSSTransition is not a direct child of TransitionGroup
            nodeRef={nodeRef}
            exit={false}
            timeout={250 + ((index || 1) * 50)}
            classNames={{
                enter: styles.loanCardTransition_enter,
                enterActive: styles.loanCardTransition_enter_active
            }}
        >
            <CardComponent
                nodeRef={nodeRef}
                className={className}
                indentContent={false}
                expandedContent={expandedContent}
                variant="outlined"
            >
                <div className={clsx(styles.mainRow, {
                    // need to reduce the gap to fit the lock button
                    [styles.compact]: loan?.registrationType === RegistrationType.FLOAT
                })}
                >
                    <Typography
                        color="textSecondary"
                        className={clsx(styles.loanNumber, {
                            [styles.customerView]: accountUserType === CUSTOMER
                        })}
                    >
                        Loan #

                        <MuiLink
                            component={Link}
                            to={`${getAccountBaseRoute(clientId, accountCustomerId)}/loans/${loanId}${loan ? '' : '/pending'}/detail/all`}
                        >
                            {loanNumber}
                        </MuiLink>

                        <IconButton
                            component={Link}
                            to={`${loanId}/loan-data`}
                            state={{ isPendingLoan }}
                            tooltip="View loan data"
                        >
                            <OpenInNew
                                color="secondary"
                                fontSize="small"
                            />
                        </IconButton>

                        {loanId && loanNumber && !isPendingLoan && (
                            <Badge
                                color={(docsLoading || numDocuments === undefined) ? 'default' : 'primary'}
                                classes={{
                                    badge: styles.badge
                                }}
                                badgeContent={docsLoading ? (
                                    <Skeleton className={styles.skeleton} />
                                ) : numDocuments ?? ''}
                            >
                                <IconButton
                                    component={Link}
                                    to={`${loanId}/loan-documents`}
                                    tooltip={`View loan documents (${numDocuments ?? 0})`}
                                    state={loanNumber}
                                >
                                    <Description
                                        color="secondary"
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Badge>
                        )}
                    </Typography>

                    {!accountCustomerId && customerName && (
                        <Chip
                            size="small"
                            label={customerName}
                            className={styles.chip}
                        />
                    )}

                    <div className={styles.wrapper}>
                        {loanAmount ? (
                            <CurrencyTypography
                                value={loanAmount}
                                variant="body2"
                                className={styles.loanAmount}
                            />
                        ) : (
                            <Typography
                                variant="body2"
                                className={styles.loanAmount}
                            >
                                N/A
                            </Typography>
                        )}

                        {isProductAndPricingPage && (
                            <>
                                <div className={styles.rateContainer}>
                                    {showWarning && (
                                        <Tooltip title="Note rate has been adjusted for pricing display but hasn't been applied to the loan yet">
                                            <WarningAmber
                                                fontSize="small"
                                                color="warning"
                                            />
                                        </Tooltip>
                                    )}

                                    <Typography variant="body2">
                                        <span className={styles.hidden}>$</span>{displayRate ? `${displayRate.toFixed(3)}%` : 'N/A'}
                                    </Typography>

                                    <IconButton
                                        size="small"
                                        tooltip="View note rates"
                                        onClick={handleRateClick}
                                    >
                                        <Tune
                                            color="secondary"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </div>

                                <NoteRatePopover
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    rates={currentNoteRates}
                                    selectedRate={selectedNoteRate}
                                    onRateSelect={handleRateSelect}
                                />
                            </>
                        )}
                    </div>

                    <LabeledValue
                        label="Borrower"
                        value={borrowerName}
                        variant="vertical"
                        className={clsx(styles.borrower, {
                            [styles.customerView]: accountUserType === CUSTOMER,
                            [styles.noLock]: loan?.registrationType !== RegistrationType.FLOAT
                        })}
                    />

                    {additionalDetails}
                </div>
            </CardComponent>
        </CSSTransition>
    );
}
