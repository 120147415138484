import { Account, PermissionType, UserType } from '@api';
import {
    AccountBalance,
    AdminPanelSettings,
    AttachMoney,
    Badge,
    CreditScore,
    Dashboard,
    Description,
    FactCheck,
    Groups,
    Handshake,
    ImportContacts,
    Inventory,
    LockClock,
    Notifications,
    Person,
    PriceCheck,
    StackedLineChart,
    SwitchAccount,
    TableView,
    TextFields,
    Topic,
    Tune
} from '@mui/icons-material';
import { Popover } from '@mui/material';
import { getAccountBaseRoute } from '@utils';
import { useTryGetCurrentAccount } from '@utils/hooks';
import { useHasPermission } from '@utils/hooks/useHasPermission';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import clsx from 'clsx';
import {
    Children, ComponentProps, Dispatch, ReactElement, ReactNode, SetStateAction, cloneElement, isValidElement, useState
} from 'react';
import { useLocation } from 'react-router-dom';

import logoWhite from '../../../images/logo-white.png';

import styles from './MainNav.module.scss';
import NavItem from './components/NavItem';
import NavList from './components/NavList';


const { CLIENT, INTERNAL, CUSTOMER } = UserType;

interface MainNavProps {
    account: Account | undefined;
}

export default function MainNav({ account }: MainNavProps) {
    const { accountUserType, id: clientId, customerId } = useTryGetCurrentAccount() || {};

    const [ adminAnchorEl, setAdminAnchorEl ] = useState<HTMLElement | null>(null);
    const [ trackingAnchorEl, setTrackingAnchorEl ] = useState<HTMLElement | null>(null);

    const isAdmin = !!useLocation().pathname.match(/\/accounts\/.*\/admin/);
    const isTracking = !!useLocation().pathname.match(/\/accounts\/.*\/tracking/);

    function closeSubmenus() {
        setAdminAnchorEl(null);
        setTrackingAnchorEl(null);
    }

    const adminOpen = !!adminAnchorEl;
    const trackingOpen = !!trackingAnchorEl;
    const trackingOrAdminOpen = adminOpen || trackingOpen;

    const [
        canViewRoleManagement,
        canViewUserManagement,
        canViewCustomers,
        canViewProducts,
        canViewLockAvailability,
        canViewReferenceGuides,
        canViewPurchaseAdviceConfig,
        canViewPurchaseAdviceFee,
        canViewDataRequests,
        canViewCustomerAlertConfigs,
        canViewBulkCommitments,
        canViewLoanStatusConfigs,
        canViewLoans,
        canViewConditionConfigs,
        canViewFieldConfigs,
        canViewConfigurableValues
    ] = useHasPermission(
        [
            PermissionType.VIEW_ROLES,
            PermissionType.VIEW_USERS,
            PermissionType.VIEW_CUSTOMERS,
            PermissionType.VIEW_PRODUCTS,
            PermissionType.VIEW_LOCK_AVAILABILITY,
            PermissionType.VIEW_REFERENCE_GUIDES,
            PermissionType.VIEW_PURCHASE_ADVICE_CONFIG,
            PermissionType.VIEW_PURCHASE_ADVICE_FEE,
            PermissionType.VIEW_DUE_DILIGENCE_STEP,
            PermissionType.VIEW_CUSTOMER_ALERT_CONFIGS,
            PermissionType.VIEW_BULK_COMMITMENT,
            PermissionType.VIEW_LOAN_STATUS_CONFIGS,
            PermissionType.VIEW_LOANS,
            PermissionType.VIEW_CONDITION_CONFIGS,
            PermissionType.VIEW_FIELD_CONFIGS,
            PermissionType.VIEW_CONFIGURABLE_VALUES
        ]
    );

    const accountBaseRoute = getAccountBaseRoute(clientId!, customerId);

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const adminNavItems = (
        <>
            {accountUserType === INTERNAL && (
                <NavItem
                    to={`${accountBaseRoute}/admin/clients`}
                    label="Clients"
                    Icon={Groups}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === INTERNAL && (
                <NavItem
                    to={`${accountBaseRoute}/admin/investors`}
                    toQueryParam={eligibilityVersionId ? `?eligibilityVersionId=${eligibilityVersionId}` : ''}
                    label="Investors"
                    Icon={AccountBalance}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewBulkCommitments && (
                <NavItem
                    to={`${accountBaseRoute}/admin/bulk-commitments`}
                    label="Bulk Commitments"
                    Icon={Handshake}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewCustomers && (
                <NavItem
                    to={`${accountBaseRoute}/admin/customers`}
                    label="Customers"
                    Icon={Groups}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewCustomerAlertConfigs && (
                <NavItem
                    to={`${accountBaseRoute}/admin/customer-alert-config`}
                    label="Customer Alerts"
                    Icon={Notifications}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && (
                <NavItem
                    to={`${accountBaseRoute}/admin/investor-eligibility`}
                    toQueryParam={eligibilityVersionId ? `?eligibilityVersionId=${eligibilityVersionId}` : ''}
                    label="Investor Eligibility"
                    Icon={AccountBalance}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && (
                <NavItem
                    to={`${accountBaseRoute}/admin/llpas`}
                    label="LLPAs"
                    Icon={Tune}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewLockAvailability && (
                <NavItem
                    to={`${accountBaseRoute}/admin/lock-availability`}
                    label="Lock Availability"
                    Icon={LockClock}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewProducts && (
                <NavItem
                    to={`${accountBaseRoute}/admin/products`}
                    label="Products"
                    Icon={Inventory}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && (canViewPurchaseAdviceConfig || canViewPurchaseAdviceFee) && (
                <NavItem
                    to={`${accountBaseRoute}/admin/purchase-advice`}
                    label="Purchase Advice"
                    Icon={AttachMoney}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType !== INTERNAL && canViewReferenceGuides && (
                <NavItem
                    to={`${accountBaseRoute}/admin/reference-guides`}
                    label="Reference Guides"
                    Icon={Topic}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewRoleManagement && (
                <NavItem
                    to={`${accountBaseRoute}/admin/roles`}
                    label="Roles"
                    Icon={Badge}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {canViewUserManagement && (
                <NavItem
                    to={`${accountBaseRoute}/admin/users`}
                    label="Users"
                    Icon={Person}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CUSTOMER && canViewDataRequests && (
                <NavItem
                    to={`${accountBaseRoute}/admin/data-requests`}
                    label="Diligence Requests"
                    Icon={ImportContacts}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewLoanStatusConfigs && (
                <NavItem
                    to={`${accountBaseRoute}/admin/loan-status-configs`}
                    label="Loan Statuses"
                    Icon={FactCheck}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewConditionConfigs && (
                <NavItem
                    to={`${accountBaseRoute}/admin/condition-configs`}
                    label="Loan Conditions"
                    Icon={CreditScore}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewFieldConfigs && (
                <NavItem
                    to={`${accountBaseRoute}/admin/field-configs`}
                    label="Required Fields"
                    Icon={TextFields}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}

            {accountUserType === CLIENT && canViewConfigurableValues && (
                <NavItem
                    to={`${accountBaseRoute}/admin/configurable-values`}
                    label="Configurable Values"
                    Icon={TableView}
                    onClick={closeSubmenus}
                    dark={adminOpen}
                />
            )}
        </>
    );

    const trackingNavItem = (
        <NavItem
            to={`${accountBaseRoute}/tracking/bulk-commitments`}
            label="Bulk Commitments"
            Icon={Handshake}
            onClick={closeSubmenus}
            dark={trackingOpen}
        />
    );

    const mainNavItems = !account ? (
        <NavItem
            to="/accounts"
            label="Accounts"
            Icon={SwitchAccount}
        />
    ) : (
        <>
            <NavItem
                exact
                to={`${accountBaseRoute}/dashboard`}
                label="Dashboard"
                Icon={Dashboard}
                onClick={closeSubmenus}
                selected={trackingOrAdminOpen ? false : undefined}
            />

            {accountUserType !== INTERNAL && (
                <NavItem
                    to={`${accountBaseRoute}/loans`}
                    label="Loans"
                    Icon={Description}
                    onClick={closeSubmenus}
                    selected={trackingOrAdminOpen ? false : undefined}
                />
            )}

            {accountUserType !== INTERNAL && canViewLoans && (
                <NavItem
                    to={`${accountBaseRoute}/product-pricing`}
                    label="Product & Pricing"
                    Icon={PriceCheck}
                    onClick={closeSubmenus}
                    selected={trackingOrAdminOpen ? false : undefined}
                />
            )}

            {accountUserType !== INTERNAL && (
                <NavItem
                    selected={trackingOpen || isTracking}
                    label="Tracking"
                    Icon={StackedLineChart}
                    onClick={(event) => {
                        closeSubmenus();
                        setTrackingAnchorEl(event.currentTarget);
                    }}
                />
            )}

            {isTracking && !trackingOpen && cloneElement(trackingNavItem, {
                key: `submenu-${trackingNavItem.props.label}`,
                hideIfNoMatch: true,
                dark: true
            })}

            <NavItem
                selected={adminOpen || isAdmin}
                label="Admin"
                Icon={AdminPanelSettings}
                onClick={(event) => {
                    closeSubmenus();
                    setAdminAnchorEl(event.currentTarget);
                }}
            />

            {isAdmin && !adminOpen && Children.map(adminNavItems.props.children,
                (child: ReactElement<ComponentProps<typeof NavItem>>) => (
                    !isValidElement(child) ? child : cloneElement(child, {
                        key: `submenu-${child.props.label}`,
                        hideIfNoMatch: true,
                        dark: true
                    })
                ))}
        </>
    );

    return (
        <nav className={styles.nav}>
            <div className={styles.navGroup}>
                <img
                    alt="premicorr logo"
                    src={logoWhite}
                    className={styles.navLogo}
                />

                <div className={styles.navListTransitionContainer}>
                    <NavList
                        direction="right"
                        in
                    >
                        {mainNavItems}
                    </NavList>
                </div>
            </div>

            <NavGroupPopover
                anchorEl={adminAnchorEl}
                setAnchorEl={setAdminAnchorEl}
            >
                {adminNavItems}
            </NavGroupPopover>

            <NavGroupPopover
                anchorEl={trackingAnchorEl}
                setAnchorEl={setTrackingAnchorEl}
                className={styles.trackingNavGroup}
            >
                {trackingNavItem}
            </NavGroupPopover>
        </nav>
    );
}


interface NavGroupPopoverProps {
    children: ReactNode;
    anchorEl: HTMLElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null >>;
    className?: string;
}

function NavGroupPopover({
    anchorEl, setAnchorEl, children, className
}: NavGroupPopoverProps) {
    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            transformOrigin={{
                horizontal: 'left',
                vertical: 'top'
            }}
            classes={{
                paper: clsx(styles.subNavGroup, className)
            }}
        >
            {children}
        </Popover>
    );
}
