import {
    CommitmentType, LockPeriod, PricingResultProduct, RegistrationType
} from '@api';
import { CardTable } from '@tsp-ui/core/components';

import styles from './LoanPricingResultCard.module.scss';
import PricingResultProductRow from './PricingResultProductRow';


interface PricingResultProductsTableProps {
    isExpired: boolean;
    onRegister(productID: string, registrationType: RegistrationType, noteRate?: number): Promise<void>;
    products: PricingResultProduct[];
    loanId: string;
    currentNoteRate: number | undefined;
    originalRate: number;
    commitmentType: CommitmentType;
    lockPeriod: LockPeriod;
}

export default function PricingResultProductsTable({
    isExpired, onRegister, products, loanId, currentNoteRate, originalRate, commitmentType, lockPeriod
}: PricingResultProductsTableProps) {
    return (
        <CardTable
            className={styles.productsTable}
            headers={pricingResultTableHeaders}
            mediumBackground
        >
            {products.map(product => (
                <PricingResultProductRow
                    key={product.productId}
                    product={product}
                    onRegister={onRegister}
                    isExpired={isExpired}
                    loanId={loanId}
                    currentNoteRate={currentNoteRate}
                    originalRate={originalRate}
                    commitmentType={commitmentType}
                    lockPeriod={lockPeriod}
                />
            ))}
        </CardTable>
    );
}

const pricingResultTableHeaders = [
    '',
    'Eligible product',
    'Investor',
    'Margin',
    'Base price',
    'LLPAs',
    'Final price',
    ' '
];
