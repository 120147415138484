import { AlertType } from '@api';

import { apiUtils, getAccountBaseUrl } from '../api-utils';


export interface Alert {
    id: string;
    customerId: string;
    date: string;
    isActive: boolean;
    alertType: AlertType;
}

export function getActiveAlerts(clientID: string, customerID: string): Promise<Alert[]> {
    return apiUtils.get(`${getAccountBaseUrl(clientID, customerID)}/alerts`);
}
