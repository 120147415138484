import api, {
    AmortizationType, AutomatedUwSystem, LoanPricingResult, LoanPurpose, ManualLoanEntry, amortizationTypeDisplay,
    automatedUwRecommendationDisplay
} from '@api';
import {
    DialogContent, FormControl, FormLabel, Paper, Typography
} from '@mui/material';
import {
    AddressFieldset,
    Button, CurrencyField, DialogActions,
    PaperSaveLoader, PercentField, Radio, RadioGroup, RoutedDialog,
    RoutedDialogImplProps,
    Switch,
    TextField, isAWholeNumber, isAtLeast, isAtMost, renderEnumOptions, useForm, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount, useRenderTogglableEnumOptions } from '@utils';
import { ClientContext } from '@views/AuthenticatedRouteSwitch';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { ManualLoanEntryFormValues } from '../ManualLoanEntryPage';

import { LoanPricingResultDetails } from './LoanPricingResultCard';
import styles from './PricingExplorerDialog.module.scss';


const { DU, LPA } = AutomatedUwSystem;

export default function PricingExplorerDialog(props: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();
    const renderTogglableEnumOptions = useRenderTogglableEnumOptions();
    const formMethods = useForm<Partial<ManualLoanEntry>>();
    const { watch } = formMethods;
    const amorType = watch('amorType');
    const loanPurpose = watch('loanPurpose');
    const mortgageVisible = watch('mortgageInsFlag');
    const underwriteVisible = watch('underwriteFlag');
    const automatedUwSystem = watch('automatedUwSystem');

    const isPurchase = loanPurpose === LoanPurpose.PURCHASE;
    const showAutomatedUwRecommendation = automatedUwSystem === DU || automatedUwSystem === LPA;

    const [ scenarioPricingResult, setScenarioPricingResult ] = useState<LoanPricingResult>();
    const [ loading, setLoading ] = useState(false);

    const handleSubmit = formMethods.handleSubmit(async (formData) => {
        setLoading(true);

        try {
            setScenarioPricingResult(await api.pricing.getScenarioPricingResult(clientId, formData, customerId));
            pageMessage.success('Scenario pricing results fetched successfully');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching scenario pricing results', error);
        }

        setLoading(false);
    });

    const { configurableValues } = useContext(ClientContext);
    const allowFixed = configurableValues?.togglableValues.amortizationType.includes(AmortizationType.FIXED);
    const allowAdjustable = configurableValues?.togglableValues.amortizationType.includes(AmortizationType.ADJUSTABLE);

    return (
        <RoutedDialog
            {...props}
            title="Pricing Explorer"
            fullScreen
            classes={{ paper: styles.dialogRoot }}
            className={styles.root}
        >
            <DialogContent
                className={styles.content}
            >
                <Paper className={styles.formWrapper}>
                    <form
                        noValidate
                        id="pricing-explorer-form"
                        className={styles.form}
                        onSubmit={handleSubmit}
                    >
                        <FormProvider {...formMethods}>
                            <div>
                                <FormLabel className={styles.formLabel}>
                                    Loan Details
                                </FormLabel>

                                <div className={styles.detailsContainer}>
                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="loanAmount"
                                        label="Total loan amount"
                                        rules={isAtLeast(1)}
                                    />

                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="baseLoanAmount"
                                        label="Base loan amount"
                                        required
                                        rules={isAtLeast(1)}
                                    />

                                    <PercentField<ManualLoanEntryFormValues>
                                        name="interestRate"
                                        label="Interest rate"
                                        required
                                        rules={isAtLeast(0)}
                                        noConversion
                                    />

                                    <TextField<ManualLoanEntryFormValues>
                                        name="lockPeriod"
                                        label="Lock period (days)"
                                        select
                                        defaultValue="30"
                                    >
                                        {renderTogglableEnumOptions('lockPeriod')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="loanFICO"
                                        label="Loan FICO"
                                        type="number"
                                        rules={{
                                            ...isAtLeast(300),
                                            ...isAtMost(850)
                                        }}
                                    />

                                    <TextField<ManualLoanEntryFormValues>
                                        name="dti"
                                        label="Debt to income ratio"
                                        type="number"
                                        rules={isAtLeast(0)}
                                    />

                                    <TextField<ManualLoanEntryFormValues>
                                        name="loanType"
                                        label="Loan type"
                                        select
                                        maxLength={100}
                                    >
                                        {renderTogglableEnumOptions('loanType')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="loanTerm"
                                        label="Term (months)"
                                        type="number"
                                        rules={{
                                            ...isAtLeast(1),
                                            ...isAWholeNumber
                                        }}
                                    />

                                    <TextField<ManualLoanEntryFormValues>
                                        name="loanLimitType"
                                        label="Loan limit type"
                                        select
                                        maxLength={100}
                                    >
                                        {renderTogglableEnumOptions('loanLimitType')}
                                    </TextField>

                                    <br />

                                    <Switch<ManualLoanEntryFormValues>
                                        name="escrowsFlag"
                                        label="Taxes & insurance escrowed"
                                    />

                                    <Switch<ManualLoanEntryFormValues>
                                        name="interestOnlyFlag"
                                        label="Interest only"
                                    />

                                    <FormControl
                                        component="fieldset"
                                        className={styles.amortType}
                                    >
                                        <FormLabel
                                            component="legend"
                                        >
                                            Amortization type
                                        </FormLabel>

                                        <RadioGroup<ManualLoanEntryFormValues>
                                            name="amorType"
                                            defaultValue={AmortizationType.FIXED}
                                        >
                                            {allowFixed && (
                                                <Radio
                                                    value={AmortizationType.FIXED}
                                                    label={amortizationTypeDisplay[AmortizationType.FIXED]}
                                                />
                                            )}

                                            {allowAdjustable && (
                                                <Radio
                                                    value={AmortizationType.ADJUSTABLE}
                                                    label={amortizationTypeDisplay[AmortizationType.ADJUSTABLE]}
                                                />
                                            )}

                                            {!allowFixed && !allowAdjustable && (
                                                <Typography>
                                                    No amortization types are enabled for this client.
                                                </Typography>
                                            )}
                                        </RadioGroup>
                                    </FormControl>

                                    {amorType === AmortizationType.ADJUSTABLE ? (
                                        <FormControl
                                            component="fieldset"
                                        >
                                            <FormLabel
                                                component="legend"
                                                required
                                            >
                                                Amortization details
                                            </FormLabel>

                                            <div className={styles.amortizationDetailsFields}>
                                                <CurrencyField<ManualLoanEntryFormValues>
                                                    name="armMargin"
                                                    label="Margin"
                                                    size="small"
                                                    required
                                                    hideRequiredIndicator
                                                    rules={isAtLeast(0)}
                                                />

                                                <CurrencyField<ManualLoanEntryFormValues>
                                                    name="armInitialCap"
                                                    label="Initial cap"
                                                    size="small"
                                                    required
                                                    hideRequiredIndicator
                                                    rules={isAtLeast(0)}
                                                />

                                                <CurrencyField<ManualLoanEntryFormValues>
                                                    name="armSubsequentCaps"
                                                    label="Subsequent cap"
                                                    size="small"
                                                    required
                                                    hideRequiredIndicator
                                                    rules={isAtLeast(0)}
                                                />

                                                <CurrencyField<ManualLoanEntryFormValues>
                                                    name="armLifeCap"
                                                    label="Life cap"
                                                    size="small"
                                                    required
                                                    hideRequiredIndicator
                                                    rules={isAtLeast(0)}
                                                />
                                            </div>
                                        </FormControl>
                                    ) : <div />}
                                </div>
                            </div>

                            <div>
                                <FormLabel className={styles.formLabel}>
                                    Property Details
                                </FormLabel>

                                <div className={styles.detailsContainer}>
                                    <TextField<ManualLoanEntryFormValues>
                                        name="propertyType"
                                        label="Property type"
                                        select
                                    >
                                        {renderTogglableEnumOptions('propertyType')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="propertyUnits"
                                        label="Number of units"
                                        select
                                        defaultValue="1"
                                    >
                                        {renderTogglableEnumOptions('numUnits')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="loanPurpose"
                                        label="Purpose"
                                        select
                                    >
                                        {renderTogglableEnumOptions('loanPurpose')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="occupancyType"
                                        label="Occupancy"
                                        select
                                    >
                                        {renderTogglableEnumOptions('occupancyType')}
                                    </TextField>

                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="appraisedValue"
                                        label="Appraised value"
                                        rules={isAtLeast(0)}
                                    />

                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="salesPrice"
                                        label="Sales price (optional)"
                                        rules={isAtLeast(0)}
                                    />

                                    <AddressFieldset<ManualLoanEntryFormValues>
                                        required
                                        className={styles.addressField}
                                        fieldNames={{
                                            city: 'propertyCity',
                                            state: 'propertyState',
                                            zip: 'propertyZipCode',
                                            county: 'propertyCounty'
                                        }}
                                    />
                                </div>
                            </div>

                            <div>
                                <FormLabel className={styles.formLabel}>
                                    Additional Details
                                </FormLabel>

                                <div className={styles.detailsContainer}>
                                    <TextField<ManualLoanEntryFormValues>
                                        name="specialtyProgram"
                                        label="Specialty program"
                                        select
                                    >
                                        {renderTogglableEnumOptions('specialtyProgram')}
                                    </TextField>

                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="subordinatedBalance"
                                        label="Subordinated balance"
                                        rules={isAtLeast(0)}
                                    />

                                    <CurrencyField<ManualLoanEntryFormValues>
                                        name="cashOutAmount"
                                        label="Cash out amount"
                                        rules={isAtLeast(0)}
                                        disabled={isPurchase}
                                    />

                                    <TextField<ManualLoanEntryFormValues>
                                        name="documentationType"
                                        label="Documentation"
                                        select
                                    >
                                        {renderTogglableEnumOptions('documentationType')}
                                    </TextField>

                                    <TextField<ManualLoanEntryFormValues>
                                        name="commitmentType"
                                        label="Commitment type"
                                        select
                                    >
                                        {renderTogglableEnumOptions('commitmentType')}
                                    </TextField>

                                    <br />

                                    <Switch<ManualLoanEntryFormValues>
                                        name="firstTimeHomeBuyer"
                                        label="First time home buyer"
                                    />

                                    <br />

                                    <Switch<ManualLoanEntryFormValues>
                                        className={styles.switch}
                                        name="mortgageInsFlag"
                                        label="Mortgage insurance"
                                    />

                                    {mortgageVisible ? (
                                        <FormControl
                                            component="fieldset"
                                        >
                                            <TextField<ManualLoanEntryFormValues>
                                                name="mortgageInsCompany"
                                                label="Insurance Company"
                                                maxLength={100}
                                            />
                                        </FormControl>
                                    ) : <br />}

                                    <Switch<ManualLoanEntryFormValues>
                                        className={styles.switch}
                                        name="underwriteFlag"
                                        label="Underwrite"
                                    />

                                    {underwriteVisible && (
                                        <FormControl
                                            component="fieldset"
                                        >
                                            <TextField<ManualLoanEntryFormValues>
                                                name="automatedUwSystem"
                                                label="UW System"
                                                select
                                            >
                                                {renderTogglableEnumOptions('automatedUnderwritingSystem')}
                                            </TextField>

                                            {showAutomatedUwRecommendation ? (
                                                <TextField<ManualLoanEntryFormValues>
                                                    name="automatedUwRecommendation"
                                                    label="UW Recommendation"
                                                    select
                                                >
                                                    {renderEnumOptions(automatedUwRecommendationDisplay)}
                                                </TextField>
                                            ) : null}
                                        </FormControl>
                                    )}
                                </div>
                            </div>
                        </FormProvider>
                    </form>
                </Paper>

                <Paper className={styles.pricingResultsSection}>
                    <Typography
                        variant="h6"
                        className={styles.pricingResultsTitle}
                    >
                        Pricing Results
                    </Typography>


                    <div className={styles.pricingResults}>
                        {scenarioPricingResult ? (
                            <div className={styles.resultPaper}>
                                <LoanPricingResultDetails
                                    loanId={scenarioPricingResult.loanId}
                                    pricingResultId={scenarioPricingResult.id}
                                    interestRate={scenarioPricingResult.interestRate}
                                    selectedNoteRate={5.625}
                                />
                            </div>
                        ) : (
                            <Typography>
                                Search to see scenario pricing results.
                            </Typography>
                        )}
                    </div>

                    <PaperSaveLoader loading={loading} />
                </Paper>
            </DialogContent>

            <DialogActions
                className={styles.actions}
            >
                <Button
                    variant="contained"
                    type="submit"
                    form="pricing-explorer-form"
                    disabled={loading}
                >
                    Search
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}
