import api, { RateSheet } from '@api';
import {
    Button, DialogContent, Divider, Typography
} from '@mui/material';
import {
    DateField,
    DialogActions, FileInput, usePageMessage
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { LockAvailabilityContext } from '@views/admin/lockAvailability/LockAvailabilityPage';
import { isBefore } from 'date-fns';
import {
    Dispatch, SetStateAction, useContext, useState
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './RateSheetForm.module.scss';


interface RateSheetFormProps {
    closeForm: () => void;
    setRateSheets: Dispatch<SetStateAction<RateSheet[]>>;
}

export default function RateSheetForm({ closeForm, setRateSheets }: RateSheetFormProps) {
    const [ excelFile, setExcelFile ] = useState<File | null>(null);
    const [ pdfFile, setPdfFile ] = useState<File | null>(null);
    const [ loading, setLoading ] = useState(false);

    const pageMessage = usePageMessage();
    const { id: clientId, customerId } = useGetCurrentAccount();
    const { lockSuspension, setLockSuspension } = useContext(LockAvailabilityContext);

    const formMethods = useForm<RateSheet>();
    const { watch, reset } = formMethods;

    const effectiveDate = watch('effectiveDate');

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        formValues.effectiveDate = (new Date()).toISOString();
        formValues.expirationDate = !formValues.expirationDate ? null : formValues.expirationDate;

        try {
            const files = [ excelFile, pdfFile ].filter(Boolean) as File[];
            const newRateSheet = await api.pricing.createRateSheet(clientId, {
                rateSheet: formValues,
                files
            });

            setRateSheets((rateSheets) => rateSheets.map(rateSheet => ({
                ...rateSheet,
                isCurrent: false,
                expirationDate: formValues.effectiveDate ? formValues.effectiveDate : null
            })).concat(newRateSheet));

            let subMessages: string[] | undefined = undefined;
            if (lockSuspension?.isSuspendedUntilNewRateSheet) {
                subMessages = [ 'Lock suspension removed' ];
                setLockSuspension(await api.availability.getCurrentLockSuspension(
                    clientId, customerId
                ));
            }

            reset();
            setExcelFile(null);
            setPdfFile(null);
            closeForm();

            pageMessage.success('Rate sheet added', subMessages);
        } catch (error) {
            pageMessage.handleApiError('An error occurred while adding the rate sheet', error);
        }

        setLoading(false);
    });

    return (
        <>
            <DialogContent>
                <form
                    noValidate
                    id="add-rate-sheet-form"
                    className={styles.dialogFormRoot}
                    onSubmit={handleSubmit}
                >
                    <FormProvider {...formMethods}>
                        <>
                            <div className={styles.fileInputContainer}>
                                <div>
                                    <Typography
                                        align="center"
                                    >
                                        Excel Document
                                    </Typography>

                                    <FileInput
                                        title="document"
                                        acceptedFileTypes={[ 'xlsx' ]}
                                        className={styles.fileInput}
                                        files={excelFile ? [ excelFile ] : []}
                                        inputId="upload-excel-sheet"
                                        onAddFiles={(addedFiles) => setExcelFile(addedFiles[0])}
                                        onRemoveFile={() => setExcelFile(null)}
                                        compact
                                    />
                                </div>

                                <div>
                                    <Typography
                                        align="center"
                                    >
                                        PDF Viewable (optional)
                                    </Typography>

                                    <FileInput
                                        title="document"
                                        acceptedFileTypes={[ 'pdf' ]}
                                        className={styles.fileInput}
                                        files={pdfFile ? [ pdfFile ] : []}
                                        inputId="upload-pdf-sheet"
                                        onAddFiles={(addedFiles) => setPdfFile(addedFiles[0])}
                                        onRemoveFile={() => setPdfFile(null)}
                                        compact
                                    />
                                </div>
                            </div>

                            {excelFile && (
                                <>
                                    <Divider />

                                    <div className={styles.date}>
                                        <DateField<RateSheet>
                                            name="expirationDate"
                                            label="Expiration date"
                                            pickerProps={{
                                                disablePast: true,
                                                shouldDisableDate: (date) => isBefore(date, new Date(effectiveDate))
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button onClick={() => {
                    closeForm();
                    reset();
                    setExcelFile(null);
                    setPdfFile(null);
                }}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    form="add-rate-sheet-form"
                    disabled={loading}
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
