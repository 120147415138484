import { Link as MuiLink, Tooltip } from '@mui/material';
import { useCreateAccountUrl } from '@utils';
import { BulkCommitmentsContext } from '@views/admin/bulk-commitment/BulkCommitmentManagementPage';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';


interface ProductNoteRateDisplayProps {
    productId: string | undefined;
    noteRate: number | undefined;
}

export function ProductNoteRateDisplay({ productId, noteRate }: ProductNoteRateDisplayProps) {
    const { products } = useContext(BulkCommitmentsContext);
    const createAccountUrl = useCreateAccountUrl();

    const product = products?.find(({ id }) => id === productId);

    return (
        <>
            {!product ? 'All Products' : (
                <Tooltip title={product.productDescription}>
                    <MuiLink
                        component={Link}
                        to={`${createAccountUrl(`admin/products/${productId}`)}`}
                    >
                        {product.productCode}
                    </MuiLink>
                </Tooltip>
            )}

            &nbsp;/&nbsp;

            {noteRate ? `${(noteRate * 100).toFixed(3)}%` : 'All note rates'}
        </>
    );
}
