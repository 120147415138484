export enum AlertType {
    LICENSE_EXPIRATION = 'LICENSE_EXPIRATION',
    REFERENCE_GUIDE = 'REFERENCE_GUIDE',
    ELIGIBILITY = 'ELIGIBILITY',
    LOCK_AVAILABILITY = 'LOCK_AVAILABILITY',
    COMPENSATION_FEE = 'COMPENSATION_FEE',
    ELIGIBLE_PRODUCT = 'ELIGIBLE_PRODUCT',
    LIMIT = 'LIMIT'
}

export const alertTypeConfigDisplay = {
    [AlertType.LICENSE_EXPIRATION]: 'When a state license expires',
    [AlertType.REFERENCE_GUIDE]: 'When reference guides change',
    [AlertType.ELIGIBILITY]: 'When eligibility changes',
    [AlertType.LOCK_AVAILABILITY]: 'When lock availability changes',
    [AlertType.COMPENSATION_FEE]: 'When compensation or fees change',
    [AlertType.ELIGIBLE_PRODUCT]: 'When eligible products change',
    [AlertType.LIMIT]: 'When a limit changes'
};

export const alertTypeDisplay = {
    [AlertType.LIMIT]: 'Lending limits changed',
    [AlertType.REFERENCE_GUIDE]: 'Reference guide changed',
    [AlertType.LICENSE_EXPIRATION]: 'License expired',
    [AlertType.ELIGIBILITY]: 'Eligibility changed',
    [AlertType.LOCK_AVAILABILITY]: 'Lock availability changed',
    [AlertType.COMPENSATION_FEE]: 'Compensation or fees changed',
    [AlertType.ELIGIBLE_PRODUCT]: 'Eligible products changed'
};
