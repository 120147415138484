import { Alert, AlertType } from '@api';
import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';


let alertID = 100;

export const mocks = [
    rest.get(getMockUrl('/client/:clientID/customer/:customerID/alerts'), (req, res, ctx) => {
        const { customerID } = req.params;

        const activeAlerts = alerts.filter(alert => alert.customerId === customerID && alert.isActive);

        return res(
            ctx.status(200),
            ctx.json(activeAlerts)
        );
    })
];

export const alerts: Alert[] = [
    {
        id: `${alertID++}`,
        customerId: '1',
        date: new Date().toISOString(),
        isActive: true,
        alertType: AlertType.LICENSE_EXPIRATION
    },
    {
        id: `${alertID++}`,
        customerId: '1',
        date: new Date().toISOString(),
        isActive: false,
        alertType: AlertType.COMPENSATION_FEE
    },
    {
        id: `${alertID++}`,
        customerId: '2',
        date: new Date().toISOString(),
        isActive: true,
        alertType: AlertType.LOCK_AVAILABILITY
    }
];
