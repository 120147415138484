import { Edit } from '@mui/icons-material';
import {
    Paper, Typography
} from '@mui/material';
import { IconButton } from '@tsp-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styles from './EditableSectionCard.module.scss';


interface EditableSectionCardProps {
    children: ReactNode;
    header: ReactNode;
    headerIcon?: ReactNode;
    editTo: LinkProps['to'];
    readOnly?: boolean;
    disableEdit?: boolean;
    disabledTooltip?: string;
    className?: string;
}

export default function EditableSectionCard({
    children, header, headerIcon, editTo, readOnly, className, disableEdit, disabledTooltip
}: EditableSectionCardProps) {
    return (
        <Paper
            className={clsx(styles.root, className)}
            variant="outlined"
        >
            <Typography
                variant="h6"
                className={styles.title}
            >
                {header}

                <div className={styles.buttonIconContainer}>
                    {headerIcon}

                    <IconButton
                        component={Link}
                        to={editTo}
                        tooltip={disableEdit ? disabledTooltip || '' : 'Edit'}
                        disabled={disableEdit}
                        edge="end"
                        className={clsx({
                            [styles.readOnlyButton]: readOnly
                        })}
                    >
                        <Edit color="secondary" />
                    </IconButton>
                </div>
            </Typography>

            {children}
        </Paper>
    );
}
