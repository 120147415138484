import { TimeString, formatDistanceToNowStrict } from '@tsp-ui/core';
import {
    addDays, format, isBefore, isValid, parseISO
} from 'date-fns';


export function fromLocalToUtcTime(time: TimeString): TimeString {
    const date = new Date();
    date.setHours(Number(time.split(':')[0]));
    date.setMinutes(Number(time.split(':')[1]));

    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}` as TimeString;
}

/**
 * Transforms a UTC time string to local date.
 * Takes a a time string in the format 'HH:mm' and returns a Date object with the time converted to the local timezone.
 */
export function parseUtcTime(time: TimeString | null | undefined, referenceDate: Date = new Date()): Date {
    const dateSection = format(referenceDate, 'yyyy-MM-dd');
    const fullUtcString = `${dateSection}T${time}:00Z`;
    return parseISO(fullUtcString);
}

export function parseUtcTimeRange(
    start: TimeString | null | undefined, end: TimeString | null | undefined
): [ Date, Date ] {
    const startDate = parseUtcTime(start);
    let endDate = parseUtcTime(end);

    if (isBefore(endDate, startDate)) {
        endDate = addDays(endDate, 1);
    }

    return [ startDate, endDate ];
}

export function formatTimeFromDate(date: Date): string {
    if (!isValid(date)) {
        return 'N/A';
    }

    return format(date, 'h:mm a');
}

export function formatPastDate(date: string): string {
    const formattedTime = formatDistanceToNowStrict(parseISO(date));

    return formattedTime === 'just now' ? formattedTime : `${formattedTime} ago`;
}
