import { PricingResultIneligibleProduct } from '@api';
import { Link as MuiLink, Typography } from '@mui/material';
import { CardTable } from '@tsp-ui/core/components';

import styles from './PricingResultIneligibleProductsTable.module.scss';


interface PricingResultIneligibleProductsTableProps {
    ineligibleProducts: PricingResultIneligibleProduct[] | undefined;
}

export default function PricingResultIneligibleProductsTable({
    ineligibleProducts
}: PricingResultIneligibleProductsTableProps) {
    return !ineligibleProducts ? null : (
        <CardTable
            mediumBackground
            className={styles.productsTable}
            headers={[
                'Ineligible product',
                'Reason for ineligibility'
            ]}
        >
            {ineligibleProducts.map(product => (
                <tr
                    className={styles.tableRow}
                    key={product.productId}
                >
                    <td>
                        <Typography
                            component={MuiLink}
                            className={styles.description}
                            variant="body2"
                        >
                            {product.description}
                        </Typography>
                    </td>

                    <Typography
                        component="td"
                        variant="body2"
                        width="100%"
                    >
                        {product.ineligibleReason}
                    </Typography>
                </tr>
            ))}
        </CardTable>
    );
}
