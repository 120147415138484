import api, { Role, UserType } from '@api';
import { useGetCurrentAccount } from '@utils/hooks';
import { createContext, useCallback } from 'react';

import AdminPageTemplate, {
    AdminEntityGroup, AdminEntityGroupProps, AdminPageContextValues, defaultAdminPageContextValues
} from '../components/AdminPageTemplate';

import RoleDetailPage from './RoleDetailPage';
import { RoleCard } from './components/RoleCard';
import RoleForm from './components/RoleForm';


export const RoleManagementPageContext = createContext<AdminPageContextValues<Role>>(defaultAdminPageContextValues);

export default function RoleManagementPage() {
    const { accountUserType, id: clientID } = useGetCurrentAccount();
    const fetchRoles = useCallback(() => (
        api.roles.getRoles(clientID)
    ), [ clientID ]);

    return (
        <AdminPageTemplate
            Context={RoleManagementPageContext}
            CreateEditForm={RoleForm}
            DetailPage={RoleDetailPage}
            entityName="role"
            EntityGroupComponent={RoleGroup}
            fetchEntities={fetchRoles}
            filterByLabel="name or description"
            filterEntity={({ name, description }, filterInputValue) => (
                name.toLocaleLowerCase().includes(filterInputValue)
                || description.toLocaleLowerCase().includes(filterInputValue)
            )}
            visibleGroups={userTypeMap[accountUserType]}
        />
    );
}

function RoleGroup({
    entities: customers,
    group: userTypeProp,
    name
}: AdminEntityGroupProps<Role, UserType>) {
    const displayName = userTypeProp === 'CLIENT' ? name : 'Customer';

    return (
        <AdminEntityGroup header={`${displayName} users`}>
            {customers
                .filter(({ type }) => type === userTypeProp)
                .map((role) => (
                    <RoleCard
                        key={role.id}
                        role={role}
                    />
                ))}
        </AdminEntityGroup>
    );
}

const { INTERNAL, CLIENT, CUSTOMER } = UserType;

const userTypeMap = {
    [INTERNAL]: [ INTERNAL ],
    [CLIENT]: [ CLIENT, CUSTOMER ],
    [CUSTOMER]: []
};
