import api, {
    ClientInvestor, CommonLoanProgram, EligibilityVersion, ManagedEligibilityStatus
} from '@api';
import { useAsyncEffect, useParams } from '@tsp-ui/core';
import { SentryRoutes, usePageMessage } from '@tsp-ui/core/components';
import { useActingClientID } from '@utils/hooks';
import { AdminRouteParams } from '@views/admin/components/AdminPageTemplate';
import CreateLoanProgramPage from '@views/admin/investors/InvestorDetailPage/CreateLoanProgramPage';
import { useEligibilityVersionIdQueryParam } from '@views/admin/investors/components/EligibilityVersionButton';
import { isFuture, parseISO } from 'date-fns';
import {
    Dispatch, SetStateAction, createContext, useCallback, useMemo, useState
} from 'react';
import { Route } from 'react-router-dom';

import { LoanProgramsContext } from '../investors/InternalInvestorDetailRoutes';

import { ClientAddInvestorPage } from './ClientAddInvestorPage';
import ClientInvestorDetailPage from './ClientInvestorDetailPage';
import { ClientLoanProgramDetailPage } from './ClientLoanProgramDetailPage';
import InvestorEligibilityManagementPage from './InvestorEligibilityManagementPage';


export interface InvestorEligibilityContextValue {
    investors: ClientInvestor[] | undefined;
    setInvestors: Dispatch<SetStateAction<ClientInvestor[] | undefined>>;
    eligibilityVersions: EligibilityVersion[] | undefined;
    setEligibilityVersions: Dispatch<SetStateAction<EligibilityVersion[] | undefined>>;
    isSelectedVersionEditable: boolean;
}

export const InvestorEligibilityContext = createContext<InvestorEligibilityContextValue>({
    investors: undefined,
    setInvestors: () => {},
    eligibilityVersions: undefined,
    setEligibilityVersions: () => {},
    isSelectedVersionEditable: false
});

export default function InvestorEligibilityRoutes() {
    const pageMessage = usePageMessage();
    const clientID = useActingClientID();

    const [ investors, setInvestors ] = useState<ClientInvestor[]>();
    const [ eligibilityVersions, setEligibilityVersions ] = useState<EligibilityVersion[]>();

    const eligibilityVersionId = useEligibilityVersionIdQueryParam();
    const selectedVersion = eligibilityVersions?.find(({ id }) => id === eligibilityVersionId);
    const isSelectedVersionEditable = !!selectedVersion && isFuture(parseISO(selectedVersion.effectiveDate));

    const investorEligibilityContextValue = useMemo(() => ({
        investors,
        setInvestors,
        eligibilityVersions,
        setEligibilityVersions,
        isSelectedVersionEditable
    }), [
        investors, setInvestors, eligibilityVersions, setEligibilityVersions, isSelectedVersionEditable
    ]);

    useAsyncEffect(useCallback(async () => {
        try {
            setInvestors(undefined);
            const investors = await api.client.investors.getInvestors(clientID, eligibilityVersionId);

            setInvestors(investors.filter(({ managedEligibilityStatus, sellerServicerNumber }) => (
                managedEligibilityStatus !== ManagedEligibilityStatus.MANAGED || !!sellerServicerNumber
            )));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching investors', error);
        }
    }, [
        clientID, eligibilityVersionId, pageMessage
    ]));

    useAsyncEffect(useCallback(async () => {
        try {
            setEligibilityVersions(await api.client.eligibilityVersions.getEligibilityVersions(clientID));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching eligibility versions', error);
        }
    }, [ clientID, pageMessage ]));

    return (
        <InvestorEligibilityContext.Provider value={investorEligibilityContextValue}>
            <SentryRoutes>
                <Route
                    path="*"
                    element={<InvestorEligibilityManagementPage />}
                />

                <Route
                    path="investors/add"
                    element={<ClientAddInvestorPage />}
                />

                <Route
                    path="investors/:investorID/*"
                    element={(
                        <InvestorRoutes />
                    )}
                />
            </SentryRoutes>
        </InvestorEligibilityContext.Provider>
    );
}

function InvestorRoutes() {
    const pageMessage = usePageMessage();
    const clientID = useActingClientID();
    const { investorID } = useParams<AdminRouteParams<'investor'>>();
    const eligibilityVersionId = useEligibilityVersionIdQueryParam();

    const [ loanPrograms, setLoanPrograms ] = useState<CommonLoanProgram[]>();
    const loanProgramsContextValue = useMemo(() => ({
        loanPrograms,
        setLoanPrograms
    }), [ loanPrograms, setLoanPrograms ]);

    useAsyncEffect(useCallback(async () => {
        try {
            if (investorID !== 'add') {
                setLoanPrograms(await api.client.investors.getLoanPrograms(clientID, investorID, eligibilityVersionId));
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching loan programs', error);
        }
    }, [
        clientID, investorID, eligibilityVersionId, pageMessage, setLoanPrograms
    ]));

    return (
        <LoanProgramsContext.Provider value={loanProgramsContextValue}>
            <SentryRoutes>
                <Route
                    path="*"
                    element={<ClientInvestorDetailPage />}
                />

                <Route
                    path="loan-programs/new"
                    element={<CreateLoanProgramPage clientId={clientID} />}
                />

                <Route
                    path="loan-programs/:loanProgramID/*"
                    element={<ClientLoanProgramDetailPage />}
                />
            </SentryRoutes>
        </LoanProgramsContext.Provider>
    );
}
