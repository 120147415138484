import api, { Alert } from '@api';
import { Skeleton, Typography } from '@mui/material';
import { useAsyncEffect, usePageMessage } from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import { useCallback, useState } from 'react';

import { AlertCard } from './AlertCard';
import styles from './AlertSection.module.scss';


export function AlertSection() {
    const [ alerts, setAlerts ] = useState<Alert[]>([]);
    const [ loading, setLoading ] = useState(true);
    const { id: clientId, customerId } = useGetCurrentAccount();
    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        try {
            setAlerts(await api.alert.getActiveAlerts(clientId, customerId!));
        } catch (error) {
            pageMessage.handleApiError('An error occured while fetching alerts', error);
        }

        setLoading(false);
    }, [
        clientId, customerId, pageMessage
    ]));

    return (
        <div>
            <Typography className={styles.header}>
                Alerts
            </Typography>

            <div className={styles.alerts}>
                {loading ? (
                    <Skeleton height={34} />
                ) : !alerts.length ? (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                    >
                        You have no active alerts
                    </Typography>
                ) : alerts.map((alert) => (
                    <AlertCard
                        key={alert.id}
                        alert={alert}
                    />
                ))}
            </div>
        </div>
    );
}
